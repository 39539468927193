#my-contents-footer-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden; 
  white-space: nowrap;
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0);
  justify-content: space-between;
  z-index: 1;
  transition: 0.1s;
  user-select: none;
}

#container-button-contents{
  display: flex;
  width: 100%;
}

#container-ul-audios-footer{
  display: flex;
  background-color: #fafafa;
  border: 1px solid #ddd;
  width: 100%;

  #container-loading-animation-contents-footer{
    height: 100px;
  }
}

#container-button-footer-slide-up{
  position: fixed;
  display: flex;
  margin-left: 80vw;
  margin-top: -30px;
}

#container-button-footer-slide-up button{
  width: 60px;
  height: 30px;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  border: none;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  padding-top: 2px;
}

#container-button-footer-slide-up button img{
  width: 30px;
  height: 20px;
}

.content-player-title{
  display: flex;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 7px;
  left: 10px;
  z-index: 3;
  width: fit-content;
  background-color: black;
  opacity: 0.6;
  color: white;
  border-radius: 2px;
  padding: 2px 5px 2px 5px;
  user-select: none;
}

#ul-audio-contents {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0px;
  margin: 10px 10px 10px;
  user-select: none;
  width: 100%;
  align-content: center;

  #container-loading-spineer{
    height: 100%;
  }
}

#container-li-contents-footer{
  display: flex;
  flex-direction: row;
}

#li-contents-footer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 300px;
  height: 100%;
  user-select: none;
  align-items: center;
}

#container-title-player-content-footer{
  width: 100%;
  z-index: 3;
}

#container-conteiner-title-content-footer {
  display: flex;
  position: sticky;
  height: 1px;
  left: 120px;
  z-index: 3;
}

#container-title-content-footer{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0;
  top: 10px;
  left: 10px;
  z-index: 3;
  height: fit-content;
  width: fit-content;
  background-color: black;
  opacity: 0.6;
  color: white;
  border-radius: 2px;
  padding: 2px 5px 2px 5px;
}


#container-wave-surfer-player-my-contents-footer {
  display: flex;
  width: 100%;
  position: relative;
  left: -8px;
  z-index: 3px;
}

#container-conteiner-title-content-footer{
  display: flex;
  position: sticky;
  height: 1px;
  left: 120px;
  z-index: 3;
}

#container-content-footer-instrument-name{
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 90%;
  margin: 5px;
  background-color: black;
  align-content: center;
  padding-right: 10px;
  margin-top: 4px;
  margin-bottom: 5px;
  border-radius: 5px 0px 0px 5px;

  label{
    font-size: 10px;
    display: flex;
    margin-left: 10px;
    position: relative;
    transform: rotate(-90deg);
    text-align: center;
    justify-content: center;
    align-items:center;
    white-space: pre-wrap;
    height: 0px;
    width: 0px;
    color: white;
    user-select: none;
  }
}

#container-button-attach-content-footer{
  display: flex;
  height: 100%;
  position: relative;
  left: -8px;
}

#button-attach-content {
  display: flex;
  align-items: center;
  padding: 2px;
  color: #ddd;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  border: none;
  opacity: 0.8;
  cursor: pointer;
  bottom: 20px;
  height: 100%;
  margin-bottom: 5px;
  user-select: none;
  z-index: 3;

  img{
    height: 20px;
    user-select: none;
  }
}

#button-attach-content:hover{
  color: #ddd;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  border: none;
  opacity: 1;
  transition: background-color 0.5s ease;
  user-select: none;
}