#container-menu-search-page{
    display: flex;
    position: fixed;
    height: fit-content;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    user-select: none;
    border-radius: 0px 5px 5px 0px;
    width: 15vw;
    background-color: rgb(255, 255, 255);
    min-width: 50px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1); 

    ul{
        list-style: none;
        padding: 0px;
        width: 100%;
        margin: 0px;
        padding: 5px;
        width: 100%;

        li{
            margin: 2px 0px 2px 0px;
        }

        #deselected-option-search-menu{
            text-align: center;
            color: rgb(85, 85, 85);
            cursor: pointer;
            border-radius: 4px;
            padding: 2px;
            width: 100%;
            box-sizing: border-box;

            &:hover {
                background-color: rgb(224, 224, 224);
            }

            h1{
                font-size: 14px;
                margin: 2px 0px 2px 0px;

                @media (max-width: 500px) {
                    font-size: 14px;
                }
    
                @media (max-width: 450px) {
                    font-size: 10px;
                }
    
                @media (max-width: 400px) {
                    font-size: 10px;
                }
    
                @media (max-width: 300px) {
                    font-size: 8px;
                }

            }
        }

        #selected-option-search-menu{
            text-align: center;
            color: rgb(85, 85, 85);
            cursor: pointer;
            border-radius: 4px;
            padding: 2px;
            width: 100%;
            box-sizing: border-box;
            background-color: rgb(224, 224, 224);

            h1{
                font-size: 14px;
                margin: 2px 0px 2px 0px;

                @media (max-width: 500px) {
                    font-size: 14px;
                }
    
                @media (max-width: 450px) {
                    font-size: 10px;
                }
    
                @media (max-width: 400px) {
                    font-size: 10px;
                }
    
                @media (max-width: 300px) {
                    font-size: 8px;
                }

            }
        }
    }
}