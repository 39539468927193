#container-messages-boxes{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    width: 100%;
    background-color: transparent;
    height: 35px;
    align-items: flex-start;
    z-index: 500;
    box-sizing: border-box;

    ul{
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0px 0px 0px 10px;
        padding: 0px;
    }

    ul li {
        width: 20vw;
        margin-right: 2px;

        @media (max-width: 900px){
            width: 30vw;
        }

        #container-user-name{
            display: flex;
            background-color: rgb(60, 60, 60);
            color: #ffffff;
            border-radius: 0px 0px 5px 5px;
            padding: 5px 10px 5px 10px;
            margin: 0px;
            list-style: none;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            width: 20vw;
            cursor: pointer;
            margin-right: 2px;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            /* @media (max-width: 900px){
                width: 40vw;
            } */

            h3{
                font-size: 1vw;

                @media(max-width: 900px){
                    font-size: 2vw;
                }

                @media(max-width: 500px){
                    font-size: 3vw;
                }
            }

            button{
                display: flex;
                width: fit-content;
                height: fit-content;
                background-color: transparent;
                border: none;
                align-items: center;
                cursor: pointer;

                img{
                    height: 20px;
                    width: 20px;
                }
            }
        }
        
        #container-user-name:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
        }
    }
    
    
    ul li h3 {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0;
    }
    
    ul li p {
        font-size: 0.9rem;
        margin: 8px 0 0;
    }

    li h3{
        margin: 0px;
    }
}