
#container-ul-group-posts{
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 1000px;
    list-style: none;
    margin: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
    position: relative;
    width: 100%;

    scrollbar-color: #888 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
    }
}
  
#container-post-top-pre{
  margin: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  pre{
    margin: 0px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
}

#ul-group-posts{
  margin: 5px;
  padding: 0px;
}


#container-li-group-posts {
    flex-direction: column;
    list-style: none;
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    border: 1px solid rgb(189, 189, 189);
    margin-bottom: 5px;
}
  
#li-post{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#container-post-middle{
  flex-direction: column;
  list-style: none;
  min-height: 120px;
  height: fit-content;
  border-radius: 10px;
  margin: 5px;
  border: 1px solid rgb(189, 189, 189);
}


#container-project-player-projects{
  display: flex;
  margin-top: 0px;
  height: fit-content;
}

#container-middle-top{
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  h3{
    margin: 5px;
  }
}

#container-title-description{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#container-user-info-date{

  #container-date{


    h6{
      margin: 0px 0px 0px 10px;
      color: rgb(122, 122, 122);
    }
  }
}

#container-delete-post{
  display: flex;

  button{
    height: fit-content;
    width: fit-content;
    margin-top: 10px;
    margin-right: 10px;
    color: #ddd;
    background-color: rgb(220, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 0.8;
    padding: 5px 10px 5px 10px;
    cursor: pointer;

    img{
      height: 15px;
    }
  }


  button:hover{
      color: #ddd;
      background-color: rgb(175, 4, 4);
      border-radius: 5px;
      border: none;
      opacity: 1;
      transition: background-color 0.5s ease;
  }
}

#container-usability{
  margin-right: 5px;
}

#container-image{
  display: flex;
  align-items: center;
  height: 50px;
  width: 50px;
  justify-content: center;
}

#container-user-img-nickname img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: scale-down;
  /* position: relative;  */
  top: 0;
  left: 0;
}

#container-user-img-nickname{
  display: flex;
  flex-direction: row;
  align-items: center;
}


#container-middle-middle{
  display: flex;
  height: 80px;
  margin-bottom: 20px;
}

#container-middle-bottom{
  display: flex;
  width: 100%;
  padding: 0px;

  button{
    justify-content: center;
    display: flex;
    color: #888;
    flex-direction: row;
    font-weight: bold;
    width: 50%;
    align-items: center;
    border: solid 1px;
    margin: none;
    cursor: pointer;

    img{
      margin-right: 5px;
    }

    h3{ 
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

#button-post-like{
  border-radius: 0px 0px 0px 10px;
}

#button-post-open-comments{
  border-radius: 0px 0px 10px 0px;
  
  img{
    height: 25px;
  }
}

#container-post-comments{
  height: fit-content;
}

#container-ul-comments{
  height: 60%;
}

#container-create-comment{
  display: flex;
  position: relative;
  top: 0;
  height: 40%;
  width: 100%;
  flex-direction: column;
  
  textarea{
    height: 80%;
    margin: 5px;
    border-radius: 5px;
  }
}

#container-button-create-comment{
  display: flex;
  justify-content: right;
  margin-right: 5px;
  margin-bottom: 5px;
}

#button-create-comment{
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  background-color: rgb(70, 70, 70);
  border-radius: 5px;
  border: none;
  height: fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

#button-create-comment:hover{
  background-color: rgb(0, 0, 0);
  box-shadow: none;
}