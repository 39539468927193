#container-form-privacity-config{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: fit-content;
    min-width: 50vw;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(85, 85, 85);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin-right: 20px;
    padding: 20px;



    #title-privacity-config{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        user-select: none;

        @media (max-width: 500px) {
            font-size: 14px;
        }
    }

    #container-inputs-form-privacity-config{
        margin: 0px;
    
    
        #label-title-contents-privacity{
            font-weight: bold;
            font-size: 12px;
            user-select: none;
    
            @media (max-width: 500px) {
                font-size: 10px;
            }
        }
    
        #label-title-projects-privacity{
            font-weight: bold;
            font-size: 12px;
            user-select: none;
    
            @media (max-width: 500px) {
                font-size: 10px;
            }
        }


    #container-contents-privacity-config{
        display: flex;
        flex-direction: row;

        #container-label-radio-buttom{
            display: flex;
            flex-direction: row;
            align-items: center;
            user-select: none;
        
            input{
                display: flex;
                align-items: center;
                margin-left: 0px;
                margin-top: 0px;
                margin-bottom: 0px;
                height: 4vw;
            }
        
            label{
                display: flex;
                align-items: center;
                font-size: 10px;
                margin: 0px 5px 0px 0px;

                @media(max-width: 500px){
                    font-size: 8px;
                }
            }
        }
    }

    }

    @media (min-width: 1000px) {
        font-size: 2vw;
        width: 100px;
    }

    #container-button-submit-privacity-configurations{
        display: flex;
        justify-content: end;
        width: 100%;
        margin-top: 10px;
    
        button {
            display: flex;
            width: fit-content;
            height: fit-content;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            background-color: rgb(70, 70, 70);
            color: rgb(255, 255, 255);
            border-radius: 5px;
            border: none;
            height: 25px;
            width: 90px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            font-size: 10px;
            font-weight: bold;
            transition: background-color 0.3s, box-shadow 0.3s;
            
            user-select: none;
            &:hover{
                background-color: rgb(0, 0, 0);
                box-shadow: none;
            }
    
            @media (max-width: 500px) {
                font-size: 10px;
                height: 20px;
                width: 80px;
            }
/*             
            @media (min-width: 1000px) {
                font-size: 20px;
                height: 40px;
                width: 100px;
            } */
        }
    }
}