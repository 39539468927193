
.input-project-duration{
    border:none;
    background-color: #555;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    text-align: center;
    height: 40px;
    width: 40px;
}

.container-edit-duration {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    font-size: 30px;
    margin-left: auto;
}