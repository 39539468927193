#container-project-content-info-modal{
    display: flex;
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 100;
    height: 100%;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-screen-transparent-project-content-info{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    #container-button-close-project-content-info-modal{
        width: 100%;
        position: relative;
        top: -5px;


        button{
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ca2500;
            border: none;
            position: relative;
            left: 75vw;
            border-radius: 5px;
            transition: background-color 0.5s ease;

            @media (max-width: 600px){
                left: 80vw;
            }
        }
        
        button:hover{
            background-color: #a01d00;
            cursor: pointer;
        }
    }
}

#container-project-content-info{
    border-radius: 10px;
    max-width: 60vw;
    height: fit-content;
    margin: 0 auto;
    padding: 1.5em 1.5em;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    
    
    @media (min-width: 700px) {
        width: 40vw;
    }

    #container-top-project-content-info-modal{

        #container-title-description-project-content-info{
            display: flex;
            flex-direction: column;

            #container-content-info-project-content-info-modal{

                #container-title-description-project-content-info-modal{

                    #container-title-download-abled-project-content-info-modal{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        h1{
                            font-size: 20px;
                            margin: 0px
                        }

                        #container-download-abled-project-content-info-modal{
                            label{
                                font-size: 12px;
                                font-weight: bold;
                                margin-right: 5px;
                            }
                        }
                        
                    }

                    p{
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }

            }

            #container-content-duration-project-content-info-modal{
                display: flex;
                flex-direction: row;
                align-items: end;
                margin-top: 5px;
                margin-bottom: 5px;

                label {
                    font-size: 12px;
                    margin-right: 5px;
                    margin-bottom: 0px;
                    font-weight: bold;
                }

                h6{
                    margin: 0px;
                    font-weight: lighter;
                }
            }
        }
        
    }

    #container-middle-project-content-info-modal{
        #container-left-top-project-content-info-modal{

            #container-user-info-project-containet-info{
                display: flex;
                align-items: center;
                margin-top: 5px;
                margin-bottom: 5px;

                img{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    cursor: pointer;
                    object-fit: cover;
    
                    @media (max-width: 300px){
                        width: 40px;
                        height: 40px;
                    }
                }
                
                #container-user-name-nickname-project-content{
                    margin: 10px;

                    a{
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-weight: bold;
                    }
        
                    a:hover{
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    h6{
                        margin: 0px;
                        color:rgb(100, 100, 100)
                    }
                }
            }
        }
       
    }

    #container-bottom-project-content-info-modal{
        margin-top: 5px;

        #container-instrument-project-content-modal-info{

            label{
                font-weight: bold;
                font-size: 12px;
            }

            a{
                font-size: 12px;
                margin-left: 5px;
            }
        }
    
        #container-botto-right-project-content-info-modal{

            label{
                font-size: 12px;
                font-weight: bold;
            }

            a{
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
}

