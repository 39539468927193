#button-open-close-toggle-notifications{
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    #container-notifications-tag{
        position: absolute;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        #notifications-number{
            margin: 0px;
        }
    }
}

#button-open-close-toggle-notifications:hover{
    background-color: rgb(139,0,0);
}

#button-open-close-toggle-notifications img{
    padding: 5px;
}

#container-toggle-notifications {
    display: flex;
    position: fixed;
    top: 60px;
    right: 5vw;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 300px;
    width: 300px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    justify-content: center;

    @media(max-width: 500px){
        height: 250px;
        width: 250px;
    }
}

#container-toggle-notifications ul{
    padding: 0;
    margin: 0;
    justify-content: center;
    width: 100%;
}

#li-notifications {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    list-style: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    align-items: center;
}

#li-notifications img{
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

#li-notifications:hover{
    background-color: #ccc;
}

#h5-notification{
    color: rgb(0, 0, 0);
    margin: 0;
    text-align: left;
    font-weight: normal;
    user-select: none;

    @media(max-width: 500px){
        font-size: 10px;
    }
}

#notifications-number{
    display: flex;
    position: absolute;
    z-index: 2;
    top: -5px;
    right: -5px;
    border-radius: 20%;
    background-color: rgb(216, 0, 0);
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: white;
    font-size: 12px;
}
