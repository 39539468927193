#project-description-modal{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#modal-project-details-container{
        border-radius: 20px;
        max-width: 60vw;
        bottom: 0;
        height: fit-content;
        padding: 1.5em 1.5em;
        background-color: #FFF;
        box-shadow: 0 0 20px rgba(0,0,0, 0.8);
        box-sizing: border-box;


    @media (min-width: 700px) {
        width: 40vw;
    }
}

#container-bottom-row-project-details-modal{
    width: 100%;
    padding-top: 15px;
}

#container-bottom-date-tracks{
    /* display: flex; */
    width: 100%;
    /* height: fit-content; */
    flex-direction: column;
    /* height: 100%; */
    /* margin-bottom: 50px; */
}

#container-dates-usability-button-add{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    /* width: 100vw; */
    /* position: relative;
    flex-direction: row;  */

    /* @media (min-width: 500px) {
        width: 10vw;
    }    */

    #container-buttons{
        display: flex;
    }
}

#container-date-usabilty{
    display: flex;
    flex-direction: column;
    margin-right: 5px;
}

#container-date-column{
    display: flex;
    flex-direction: column;
}

#container-date-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

#container-date-row label{
    font-size: 2vw;
    white-space: nowrap;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}


#container-date-row h5{
    font-size: 2vw;
    white-space: nowrap;
    margin: 0px 0px 0px 5px;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}




#container-usability-column{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

#container-usabilty-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

#container-usabilty-row label{
    font-size: 2vw;
    white-space: nowrap;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}


#container-usabilty-row h5{
    font-size: 2vw;
    white-space: nowrap;
    margin: 0px 0px 0px 5px;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}

.date-column{
    display: flex;
    flex-direction: column;
}

.date-row{
    display:inline-flex;
    align-items:baseline;
    flex-direction: column;
    padding: none;

    label{
        white-space: nowrap;
    }
}

.date-column .date-row h5{
    display: flex;
    margin-left: 5px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
    font-size: 2vw;
    white-space: nowrap;

    @media (min-width: 300px) {
        font-size: 10px;
    }
}

.date-column .date-row label{
    font-size: 2vw;

    @media (min-width: 600px) {
        font-size: 10px;
    }
}

#container-button-close-project-details-modal{
    width: 100%;
}

#button-close-project-details-modal {
    position: relative;
    left: 75%;
    bottom: 5px;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #ca2500;
    transition: background-color 0.5s ease;
    cursor: pointer;
}

#buttpn-close-project-details-modal:hover {
    background-color: #a01d00;
}


#container-row-title {
    display: flex;
    /* flex-wrap: wrap; */
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
    /* max-width: 580px; */
    justify-content: space-between;
    width: 100%;
    align-items: center;

    /* @media (min-width: 600px) {
        width: 40vw;
    } */

    h2 {
        flex: 1;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        word-wrap: break-word;
    
        @media (max-width: 800px) {
            font-size: 18px;
        }

        @media (max-width: 700px) {
            font-size: 14px;
        }

        @media (max-width: 400px) {
            font-size: 12px;
        }
    }
    
    i {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0;
        padding-top: 0;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #888;

        @media (max-width: 700px) {
            font-size: 12px;
        }
    }
}

#row-description{
    height: 20vw;
    background-color: #fafafa;
    border: 1px solid #ddd;
    overflow-x: auto;
    padding: 5px;
    margin-top: 5px;

    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    @media (min-width: 400px) {
        height: 4em;
    }

    @media (min-width: 500px) {
        height: 20vw;
    }

    @media (min-width: 500px) {
        height: 15vw;
    }

    @media (min-width: 700px) {
        height: 10vw;
    }

    &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
      }

    
    p {
        margin-block-start: 0; /* Redefina as margens do parágrafo para evitar espaços em branco indesejados */
        margin-top: 0;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 10px;
    }
}

#button-delete{
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: rgb(255, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
    bottom: 20px;

    img{
        height: 2vw;
    }

    @media (min-width: 100px) {
        bottom: 10px;
        justify-content: center;
    }

    @media (min-width: 500px) {
        bottom: 10px;
        justify-content: center;
    }
}


#button-manage-project {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    bottom: 20px;

    img{
        height: 2vw;
    }

    @media (min-width: 100px) {
        bottom: 10px;
        justify-content: center;
    }

    @media (min-width: 500px) {
        bottom: 10px;
        justify-content: center;
    }

}

#button-manage-project:hover{
    color: #ddd;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 1;
    transition: background-color 0.5s ease;
}


#container-tracks-project-details-modal {
    display: flex; 
    position: relative; 
    height: 100%;

    #unordered-list-projects-modal {
        list-style: none;
        flex-direction: column;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 2px;
        width: 100%;
        height: 20vw;
        margin: 0px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #888 transparent;

        @media (min-width: 400px) {
            max-height: 100vw;
        }

        @media (min-width: 450px) {
            max-height: 400px;
        }

        @media (min-width: 600px) {
            max-height: 400px;
        }

        @media (min-width: 1000px) {
            max-height: 400px;
        }

        &::-webkit-scrollbar {
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 6px;
            transition: background-color 0.2s, height 0.2s;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
            height: 8px;
        }
    }
}


#li-content-modal{
    position: relative;
    width: 98%;
    height: 80px;
    box-sizing: border-box;

    /* @media (max-width: 400px){
        height: 70px;
    } */
}

#no-master-files-yet{
    text-align: center;
    color: rgb(85, 85, 85);
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    margin-top: 40px;

    @media (max-width: 400px){
        margin-top: 30px;
    }
}

#container-player-project-track-title{
    display: flex;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 2px;
    left: 10px;
    z-index: 3;
    width: fit-content;
    background-color: rgb(0, 0, 0);
    opacity: 0.8;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    padding: 2px 5px 2px 5px;

    #project-player-project-track-title{
        display: flex;
        padding: none;
        margin: 0;
        user-select: none;
    }


    h5{
        @media (max-width: 600px){
            font-size: 8px;
        }
    }
}
