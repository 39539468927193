.container-wave-surfer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    margin: 2px;
    border: 1px solid #afafaf;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.container-time-progress {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-end;
    height: fit-content;
    padding: 5px;
    font-size: 4vw;

    @media (min-width: 300px) {
        font-size: 10px;
    }


    @media (max-width: 500px) {
      font-size: 8px;
  }
}

.wave-surfer{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.wave-surfer div{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}



.container-time-from-start{
  display: flex;
  width: fit-content;
}

.container-time-to-end{
  display: flex;
  width: fit-content;
}