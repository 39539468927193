#container-info-form-config{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(70, 70, 70);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    align-items: start;
    padding: 1em;
    user-select: none;
    min-width: 500px;

    @media (max-width: 700px){
        min-width: 400px;
    }

    @media (max-width: 600px){
        min-width: 300px;
    }

    @media (max-width: 500px){
        min-width: 200px;
    }

    h1{
        font-weight: bold;
        width: 100%;
        margin: 0px;
        font-size: 20px;
    
        @media (max-width: 500px) {
            font-size: 14px;
        }
    }

    #container-information{
        margin-top: 10px;
        width: 100%;

        #container-info{
            display: flex;
            flex-direction: row;
            width: 100%;

            dt{
                text-wrap: nowrap;
                font-weight: bold;
                
                @media (max-width: 500px){
                    font-size: 10px;
                }
            
            }
            
            #container-mb{
                display: flex;
                flex-direction: row;
                margin-left: 5px;
                width: 100%;
                justify-content: right;

                dd{
                    margin-left: 5px;
                    font-weight: bold;

                    @media (max-width: 500px){
                        font-size: 10px;
                    }
                }
            }
        }

        #container-total{
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 10px;

            dt{
                text-wrap: nowrap;
                font-weight: bold;
                font-size: 40px;
                
                @media (max-width: 500px){
                    font-size: 30px;
                }
            }
            
            #container-mb{
                display: flex;
                flex-direction: row;
                margin-left: 5px;
                width: 100%;
                justify-content: right;

                dd{
                    margin-left: 5px;
                    font-weight: bold;
                    font-size: 40px;

                    @media (max-width: 500px){
                        font-size: 30px;
                    }
                }
            }
        }
    }
}