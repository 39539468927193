#container-form-labels-create-group {
    display: flex;
    justify-content: center;
}

#button-open-form {
    margin-top: 10px;
    color: white;
    background-color: rgb(70, 70, 70);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#button-open-form:hover {
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}

#container-form-create-group{
    display: relative;
    border-radius: 8px;
    width: 70vw;
    padding: 10px;
    margin: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #f3f3f3;
    border: 1px solid #ddd;

    @media (min-width: 200px) {
        max-width: 500px;
    }
}

#button-select-group-genres{
    margin-top: 10px;
    display: flex;
    color: white;
    background-color: brown;
    border-radius: 5px;
    border: none;
    height: 30px;
    width: 100px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 20px;
}

#button-select-group-genres:hover {
    background-color: darkred;
    box-shadow: none;
}

#button-create-group {
    margin-top: 10px;
    display: flex;
    color: white;
    background-color: brown;
    border-radius: 5px;
    border: none;
    height: 30px;
    width: 100px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 20px;
}

#button-create-group:hover {
    background-color: darkred;
    box-shadow: none;
}