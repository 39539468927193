#container-profile {
    display: flex;
    top: 0;
    bottom: 0;
    background-color: rgb(245, 245, 245);
    height: 100%;
    width: 100%;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    #container-center{
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: rgb(245, 245, 245);
        height: 100%;
        width: 900px;

        #container-top{
            display: flex;
            flex-direction: column;
            justify-content: end;
            background-color: transparent;
            margin: 0px 0px 0px 0px;
            height: 400px;
            width: 100%;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            #container-top-cover{
                display: flex;
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-right: 1em;
                bottom: -50px;
                z-index: 3;

                #container-user-more-info-cover{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    background-color: rgb(0, 0, 0);
                    height: fit-content;
                    width: fit-content;
                    right: 10px;
                    bottom: 60px;
                    padding: 10px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    opacity: 80%;
                    align-items: center;

                    #container-complete-name{
                        margin-bottom: 5px;

                        h2{
                            color: rgb(255, 255, 255);
                            font-size: 1vw;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            margin: 0px;
                            font-weight: bold;

                            @media (max-width: 800px){
                                font-size: 12px;
                            }
                        }
                    }

                    #container-about {
                        width: 600px;
                    
                        @media (max-width: 780px) {
                            width: 400px;
                        }
                    
                        @media (max-width: 580px) {
                            width: 350px;
                        }
                    
                        @media (max-width: 530px) {
                            width: 300px;
                        }
                    
                        @media (max-width: 450px) {
                            width: 250px;
                        }
                    
                        @media (max-width: 400px) {
                            width: 200px;
                        }
                    
                        @media (max-width: 350px) {
                            width: 150px;
                        }
                    
                        h2 {
                            color: white;
                            font-size: 0.8vw;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            margin: 0;
                            width: 100%;
                            word-wrap: break-word;
                            overflow-wrap: break-word;
                            white-space: normal; 
                    
                            @media (max-width: 800px) {
                                font-size: 8px;
                            }
                        }
                    }

                    #container-contact{
                        display: flex;
                        margin-top: 5px;
                        align-items: center;

                        h2{
                            color: white;
                            font-size: 0.8vw;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            margin: 0px 0px 0px 5px;
                            margin-right: 5px;

                            @media (max-width: 800px){
                                font-size: 8px;
                            }
                        }

                        img{
                            margin-left: 5px;
                            height: 10px;
                            width: 10px;
                        }
                    }

                    #container-location{
                        display: flex;
                        margin-top: 5px;

                        h2{
                            color: white;
                            font-size: 0.8vw;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            margin: 0px;

                            @media (max-width: 800px){
                                font-size: 8px;
                            }

                            @media (max-width: 350px){
                                font-size: 6px;
                            }
                        }

                    }

                    #container-website{
                        display: flex;
                        margin-top: 5px;

                        a{
                            font-size: 0.8vw;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            color: rgb(0, 0, 255);
                            cursor: pointer;
                            text-decoration: underline;

                            @media (max-width: 800px){
                                font-size: 8px;
                            }

                            @media (max-width: 350px){
                                font-size: 6px;
                            }
                        }

                    }
                    
                }

                #container-user-avater-nickname{
                    align-items: center;
                    display: flex;

                    #container-profile-image-top {
                        display: flex;
                        background-color: rgb(255, 255, 255);
                        border: 4px solid;
                        border-color: rgb(255, 255, 255);
                        border-radius: 50%;
                        height: fit-content;
                        margin-left: 30px;
                        width: fit-content;
                        outline: 4px solid rgb(165, 42, 42);
                        margin-right: 10px;
                        
                        img {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            object-fit: cover;

                            @media(max-width: 500px){

                                height: 70px;
                                width: 70px;
                            }
                        }
            
                    }
    
                    #container-profile-title{
                        display: flex;
                        flex-direction: column;
                        margin-top: 80px;
                        bottom: -80px; 
                        color: rgb(165, 42, 42);
                        height: fit-content;
                        width: fit-content;
                        
                        h2{
                            margin: 0px;
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

                            @media (max-width: 600px){
                                font-size: 20px;
                            }

                            @media (max-width: 550px){
                                font-size: 16px;
                            }
                        }
    
                        #container-name-last-name-profile{
                            display: flex;
                            flex-direction: row;
    
                            h3{
                                color: rgb(140, 140, 140);
                                margin: 0px;
                                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            
                            
                                @media (max-width: 600px){
                                    font-size: 18px;
                                }
    
                                @media (max-width: 550px){
                                    font-size: 14px;
                                }
                            }

                        }

                    }
                }
                

                

            }
        }


        #container-references-top {
            display: block;
            position: relative;
            border-radius: 20px;
            z-index: 2;

            #container-user-instruments-top-profile{
                display: flex;
                flex-direction: column;
                background-color: white;
                height: fit-content;
                align-content: center;
                list-style: none;
                padding-top: 50px;
                margin-bottom: 0px;
                padding-left: 10px;
    
                h3{
                    margin: 5px
                }
    
                ul{
                    display: flex;
                    align-items: center;
                    list-style: none;
                    flex-wrap: wrap;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding-left: 0px;
    
                    li {
                        display: flex;
                        width: fit-content;
                        height: fit-content;
                        padding: 2px;
                        background-color:  rgb(0, 190, 0);
                        color: rgb(255, 255, 255);
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        border: solid 1px rgb(0, 190, 0);
                        margin: 2px;
                        border-radius: 5px;
                        white-space: nowrap;
                        user-select: none;

                        h6{
                            margin: 0px;
                        }

                    }

                    #remaining-user-instruments-profile{
                        background-color: transparent;
                        color: rgb(0, 190, 0);
                        border-radius: 5px;
                        border: none;
                        padding: 2px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        cursor: pointer;
                        white-space: nowrap;
                        width: fit-content;
                        height: 100%;
                        margin: 2px;

                        h6{
                            margin: 0px;
                            cursor: pointer;
                            font-size: 16px;

                            &:hover{
                                text-decoration:underline;
                            }

                            @media (max-width: 500px){
                                font-size: 12px;
                            }
                        }

                    }

                    #li-add-user-instruments-profile{
                        background-color: transparent;
                        border: none;
                        color: rgb(0, 190, 0);
                        padding: 2px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        cursor: pointer;
                        white-space: nowrap;
                        width: fit-content;
                        height: 100%;
                        margin: 2px;

                        h6{
                            margin: 0px;
                            cursor: pointer;
                            font-size: 16px;

                            &:hover{
                                text-decoration: underline;
                            }

                            @media (max-width: 500px){
                                font-size: 12px;
                            }
                        }


                    }

                }

                li{

                }
            }
                
            }

            #container-music-genres-top-profile{
                display: flex;
                flex-direction: column;
                background-color: white;
                height: fit-content;
                align-content: center;
                list-style: none;
                margin: 0px;
                padding: 0px 10px 10px 10px;
                border-radius: 0px 0px 10px 10px;
    
                h3{
                    margin: 5px
                }
    
                ul{
                    display: flex;
                    align-items: center;
                    list-style: none;
                    flex-wrap: wrap;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding-left: 0px;
    
                    li {
                        display: flex;
                        width: fit-content;
                        height: fit-content;
                        padding: 2px;
                        background-color:  rgb(0, 190, 0);
                        color: rgb(255, 255, 255);
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        border: solid 1px rgb(0, 190, 0);
                        margin: 2px;
                        border-radius: 5px;
                        white-space: nowrap;
                        user-select: none;

                        h6{
                            margin: 0px;
                        }

                    }

                    #remaining-music-genres-profile{
                        background-color: transparent;
                        color: rgb(0, 190, 0);
                        border-radius: 5px;
                        border: none;
                        padding: 2px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        cursor: pointer;
                        white-space: nowrap;
                        width: fit-content;
                        height: 100%;
                        margin: 2px;

                        h6{
                            margin: 0px;
                            cursor: pointer;
                            font-size: 16px;

                            &:hover{
                                text-decoration:underline;
                            }

                            @media (max-width: 500px){
                                font-size: 12px;
                            }
                        }

                    }

                    #li-add-music-genres-profile{
                        background-color: transparent;
                        border: none;
                        color: rgb(0, 190, 0);
                        padding: 2px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        cursor: pointer;
                        white-space: nowrap;
                        width: fit-content;
                        height: 100%;
                        margin: 2px;

                        h6{
                            margin: 0px;
                            cursor: pointer;
                            font-size: 16px;

                            &:hover{
                                text-decoration: underline;
                            }

                            @media (max-width: 500px){
                                font-size: 12px;
                            }
                        }


                    }

                }

                li{

                }
            }
        }

        #container-roll{
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            background-color: rgb(255, 255, 255);
            height: 100%;
            border-radius: 10px 10px 0px 0px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 

            #container-profile-contents{
                width: 100%;
                border-radius: 10px 10px 0px 0px;

                #button-contents-profile{
                    width: 50%;
                    height: 50px;
                    font-size: 30px;
                    border-radius: 10px 0px 0px 0px;
                    border: none;

                    @media (max-width: 500px) {
                        font-size: 20px;
                    }
                }
        
                #button-projects-profile{
                    width: 100%;
                    height: 50px;
                    font-size: 30px;
                    border-radius: 10px 10px 0px 0px;
                    border: none;
                    
                    @media (max-width: 500px) {
                        font-size: 20px;
                    }
                }
            }
        }

        #container-music-influences-top-profile{
            display: flex;
            flex-direction: column;
            background-color: white;
            height: fit-content;
            align-content: center;
            list-style: none;
            padding-top: 0px;
            padding-bottom: 10px;
            margin-bottom: 0px;
            padding-left: 10px;
            border-radius: 0px 0px 10px 10px;

            h3{
                margin: 5px
            }

            h6{
                margin: 0px;
            }

            ul {
                display: flex;
                align-content: center;
                list-style: none;
                flex-wrap: wrap;
                margin-top: 0px;
                margin-bottom: 0px;
                padding-left: 0px;

                li {
                    display: flex;
                    width: fit-content;
                    height: fit-content;
                    padding: 2px;
                    color: rgb(0, 190, 0);
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    border: solid 1px;
                    margin: 2px;
                    border-radius: 5px;
                    white-space: nowrap;
                    user-select: none;
                }

                #remaining-music-influences-profile {
                    background-color: rgb(0, 190, 0) ;
                    border-radius: 5px;
                    color: rgb(255, 255, 255);
                    border: 1px solid rgb(0, 190, 0);
                    padding: 2px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    cursor: pointer;
                    white-space: nowrap;
                    width: fit-content;
                    height: 100%;
                    margin: 2px;

                    h6{
                        margin: 0px;
                    }

                    &:hover {
                        background-color: rgb(255, 255, 255);
                        border: 1px solid rgb(0, 190, 0);
                        color: rgb(0, 190, 0);
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    }
                }

                #li-add-more-influences-genres{
                    display: flex;
                    width: fit-content;
                    height: fit-content;
                    padding: 2px;
                    background-color: rgb(0, 190, 0);
                    color: white;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    border: solid 1px;
                    margin: 2px;
                    border-radius: 5px;
                    white-space: nowrap;
                    cursor: pointer;
        
        
                    &:hover{
                        background-color: rgb(255, 255, 255);
                        border: 1px solid rgb(0, 190, 0);
                        color: rgb(0, 190, 0);
                    };

                }
            
            }
        }


        #container-ul-user-contents-projects {
            padding: 5px;

            ul{
                display: flex;
                flex-direction: column;
                list-style: none;
                margin: 0px;
                min-height: 500px;
                padding: 0px 5px 0px 5px;
            
                p{
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 40px;
                    margin-top: 200px;
                    font-weight: bold;
                    color: rgb(199, 199, 199);
                }
            
                li {
        
                    #container-title-content-profile{
                        display: flex;
                        list-style: none;
                        position:absolute;
                        padding: 0;
                        margin-top: 5px;
                        margin-left: 10px;
                        top: 30;
                        left: 10;
                        z-index: 3;
                        width: fit-content;
                        background-color: black;
                        opacity: 0.6;
                        color: white;
                        border-radius: 2px;
                        padding: 2px 5px 2px 5px;
    
                        .title-content-profile-h5 {
                            display: flex;
                            padding: none;
                            margin: 0px;
                        }
                    }
                }
            }
            
        }

        #container-project-profile {
            margin-bottom: 5px;
            background-color: rgb(245, 245, 245);
            border: 1px solid #afafaf;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            height: 120px;
            padding-bottom: 5px;


            #container-top-profile-project-line {
                display: flex;
                width: 100%;
                flex-direction: row;
                margin-top: 5px;
                margin-bottom: 5px;
                align-items: center;

                #container-details-project-profile {

                    h3{
                        margin: 0px;
                    }
                }

                #container-details-button-project-profile {

                    #container-another-project-profile-details{
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        top: 1;
                        height: fit-content;
                        font-size: 10px;
            
                        h4{
                            display: flex;
                            padding: 0px 2px 0px 2px;
                            color: rgb(78, 78, 78);
                            margin: 0px;
                        }


                        #container-dates-project-profile{
                            display: flex;
                            flex-direction: row;
                        }
                    }

                    #container-access-project-buttons{
                        #button-more-details-project-profile {
                            display: flex;
                            background-color: rgb(70, 70, 70);
                            border-radius: 5px;
                            height: 25px;
                            z-index: 1;
                            border: none;
                            align-items: center;
                
                            &:hover{
                                cursor: pointer;
                                background-color: rgb(0, 0, 0);
                            }
                
                            img{
                                height: 20px;
                            }
                        }
                    }
                }
            }

            #container-player-project-profile {
                display: flex;
            }
        }
    }

#container-profile-buttons {
    display: flex;
    position: relative;
    bottom: -50px;

    @media(max-width: 500px){
        flex-direction: column;
        margin-top: 20px;
        bottom: -40px;
    }
}

#button-send-message{
    display: flex;
    position: relative;
    padding: 0px 5px 0px 5px;
    border: none;
    color: white;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(43, 42, 42);;
    align-items: center;
    border-radius: 5px;
    height: 50px;
    margin-left: 10px;
    width: 135px;
    justify-content: center;


    &:hover {
        background-color: rgb(0, 0, 0);
        cursor: pointer;
    }

    div{
        display: flex;
        height: 30px;

        align-items: center;
    }

    @media(max-width: 500px){
        margin-left: 0px;
    }

    @media (max-width:950px){
        height: 45px;
        width: 125px;

        img{
            height: 30px;
        }
    }

    @media (max-width:700px){
        height: 35px;
        width: 115px;

        img{
            height: 20px;
        }
    }

    h4{
    
        @media (max-width:950px){
            font-size: 10px;    
        }
    
        @media (max-width:700px){
            margin-right: 10px;
        }
    }

}

#button-fellowship-profile{
    display: flex;
    padding: 0px 5px 0px 5px;
    border: none;
    color: white;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(43, 42, 42);
    align-items: center;
    border-radius: 5px;
    height: 50px;
    width: 135px;
    padding: 5px;
    box-sizing: border-box;

    div{
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
    }

    &:hover{
        opacity: 1;
        background-color: rgb(0, 0, 0);
        cursor: pointer;
    }

    img{
        height: 30px;
    }

    @media(max-width: 500px){
        margin-bottom: 5px;
    }

    @media (max-width:950px){
        height: 45px;
        width: 125px;

    }

    @media (max-width:700px){
        height: 35px;
        width: 115px;
        font-size: 10px;

        img{
            height: 20px;
        }
    }

    h4{
    
        @media (max-width:950px){
            font-size: 10px;    
        }
    
        @media (max-width:700px){
            margin-right: 10px;
        }
    }

}