#container-form-labels-create-project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

#container-form-create-project {
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 60vw;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

#container-form-create-project h3 {
    font-size: 20px;
    color: #333;
    text-align: center;
}

#container-title-project-form,
#container-description-project-form,
#container-project-privacity {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

#container-title-project-form label,
#container-description-project-form label,
#container-project-privacity label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

#container-title-project-form input,
#container-description-project-form textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    outline: none;
    background-color: #fff;
    color: #000;
}

#container-title-project-form input:focus,
#container-description-project-form textarea:focus {
    border-color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#container-title-project-form input::placeholder,
#container-description-project-form textarea::placeholder {
    color: #888;
    opacity: 1;
}

#container-description-project-form textarea {
    resize: none;
    height: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-project-privacity {
    display: flex;
    flex-direction: column;
}

#project-privacity-form {
    display: flex;
    gap: 10px;
    align-items: center;
}

#project-privacity-form input[type="radio"] {
    margin-right: 5px;
}

#project-privacity-form span {
    font-size: 12px;
    color: #555;
}

#button-create-project {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: brown;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

#button-create-project:hover {
    background-color: darkred;
}

#button-open-project-form {
    padding: 10px;
    background-color: rgb(70, 70, 70);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#button-open-project-form:hover {
    background-color: black;
}

@media (max-width: 768px) {
    #container-form-create-project {
        width: 80vw;
    }

    #container-form-create-project h3 {
        font-size: 18px;
    }

    #container-title-project-form input,
    #container-description-project-form textarea {
        font-size: 14px;
    }

    #button-create-project {
        font-size: 14px;
        padding: 8px 16px;
    }

    #button-open-project-form {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    #container-form-create-project {
        width: 70vw;
        padding: 15px;
    }

    #container-form-create-project h3 {
        font-size: 16px;
    }

    #container-title-project-form input,
    #container-description-project-form textarea {
        font-size: 12px;
    }

    #button-create-project {
        font-size: 12px;
        padding: 6px 12px;
    }

    #button-open-project-form {
        font-size: 12px;
        padding: 6px;
    }
}
