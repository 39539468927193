#button-open-close-toggle-messages{
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

#button-open-close-toggle-messages:hover{
    background-color: rgb(139,0,0);
}

#button-open-close-toggle-messages img{
    padding: 5px;
}

#container-toggle-dialogues {
    display: flex;
    position: fixed;
    top: 60px;
    right: 5vw;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 300px;
    width: 300px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    justify-content: center;

    @media(max-width: 500px){
        height: 250px;
        width: 250px;
    }
}

#container-toggle-dialogues ul {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0px;
    width: 100%;
}

#container-toggle-dialogues ul li {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    border-bottom: 1px solid #ccc;
}

#container-toggle-dialogues ul li:hover {
    background-color: #ccc;
}

#container-toggle-dialogues ul li {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    align-items: center;
    cursor: pointer;
}

#container-toggle-dialogues ul li div{
    #p-dialogue-member-nickname{
        margin: 0px;
        font-weight: bold;
    }
}

#container-toggle-dialogues ul li #container-message {
    width: 100%;

    p{
        margin: 10px 0px 10px 10px;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
    }
}

.dialogue-member {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}

.dialogue-member img {
    height: 25px;
    width: 25px;
    margin: 0px 10px 0px 10px;
    object-fit: cover;
    border-radius: 50%;
}
