#container-search-page {
    display: flex;
    height: auto; /* Permitir que a altura se ajuste conforme necessário */
    min-height: calc(100vh - 40px); /* Garantir altura mínima para preencher a tela */
    width: 100%;
    margin-top: 40px;
    background-color: rgb(245, 245, 245);
}

#container-search-page-left,
#container-search-page-right {
    height: 100%;
    width: 20%;
    /* overflow-y: auto; Aplicar rolagem apenas às barras laterais */
}

#container-search-page-left {
    #container-side-bar {
        position: relative;
        top: 50px;
    }
}

#container-search-page-right {
    /* Estilização adicional, se necessário */
}

#container-search-page-middle {
    flex-grow: 1; /* Permitir que o contêiner central cresça conforme necessário */
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    #container-results-search {
        background-color: #f5f5f5;
        width: 100%;
        padding: 20px;
    }
}
