#container-project-content-comments-modal{
    display: flex;
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 1000;
    height: 100%;
    width: 100%;

    #container-screen-transparent-project-content-comments-modal {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        
        #container-project-content-comments {
            border-radius: 10px;
            width: 70%;
            height: fit-content;
            margin: 1em;
            padding: 0.5em 0.5em;
            background-color: #FFF;
            box-shadow: 0 0 20px rgba(0,0,0, 0.8);

            @media (min-width: 700px){
                width: 50%;
            }

            #container-project-title-project-contents-comments{
                /* margin: 2px; */
                width: 100%;
                height: fit-content;
        
                #container-top-project-content-title-button-modal{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0em 0em 0.5em 0em;
                    height: fit-content;
                    width: 100%;

                    #container-title-project-content{
                        h1{
                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                            width: auto;
                            font-size: 18px;
                            margin: 0px 10px 0px 5px;
                        }
                    }

                    button{
                        align-items: center;
                        background-color: #ca2500;
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        height: fit-content;
                        padding: 5px;
                        transition: background-color 0.5s ease;

                        &:hover {
                            background-color: #a01d00;
                            cursor: pointer;
                        }
                    }

                    /* #container-button-close-project-content-comments-modal{
                        display: flex;
                        height: 100%;
                        margin: 0px;
                        align-content: start;
                    } */

                }

                #container-middle-project-content-comments-and-container-text-area-buttom-modal{
                    display: flex;
                    flex-direction: column;
                    height: 30vh;
                    width: 100%;

                    #container-ul-project-content-comments-modal {
                        height: fit-content;
                        /* border: 1px solid #707070; */
                        border-radius: 5px;
                        height: 100%;
                        padding: 2px;
                        box-shadow: 0px 0px 4px rgba(0,0,0, 0.4);
                        
                        #ul-project-content-comments-modal{
                            list-style: none;
                            padding: 0px;
                            height: 100%;
                            overflow-y: auto;
                            margin: 0px;
        
                            scrollbar-width: thin;
                            scrollbar-color: #888 transparent;
        
                            &::-webkit-scrollbar {
                                height: 6px;
                            }
        
                            &::-webkit-scrollbar-thumb {
                                background-color: #888;
                                border-radius: 6px;
                                transition: background-color 0.2s, height 0.2s;
                            }
        
                            &::-webkit-scrollbar-thumb:hover {
                                background-color: #555;
                                height: 8px;
                            }
        
                            #container-li-project-content-comment{ 
                                border-radius: 5px;
                                margin-bottom: 2px;

                                li{
                                    display: flex;
                                    flex-direction: column;
                                    margin: 2px;

                                    #container-li-top-project-content-comment{
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;

                                        #container-user-comment-project-content-comment-modal{
                                            display: flex;
                                            align-items: start;
                                            width: 100%;

                                            #container-user-photo-project-content-comment-modal{
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                margin-top: 2px;
                                                margin-left: 2px;
    
                                                img{
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    object-fit: cover;
                                                }
    
                                            }
                                            
                                            #container-body-date-project-content-comment-modal{
                                                width: 100%;
                                                margin-left: 10px;
                                                margin-right: 5px;

                                                #container-nickname-body-delete-project-content-comment {
                                                    display: flex;
                                                    flex-direction: row;

                                                    #container-body-project-content-comment{
                                                        border-radius: 5px;
                                                        padding: 5px;
                                                        box-sizing: border-box;
                                                        width: 100%;
                                                        background-color: rgb(200, 200, 200);
                                                        margin-right: 10px;
        
                                                        h2{
                                                            margin: 0px;
                                                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                                            font-size: 12px;
                                                            cursor: pointer;
                                                            width: fit-content;
                
                                                            &:hover {
                                                                text-decoration: underline;
                                                            }
                                                        }
            
                                                        h6{
                                                            margin: 0px;
                                                            font-weight: lighter;
                                                            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                                        }
                                                    }

                                                    #container-delete-project-content-comment{
                                                        display: flex;
                                                        align-items: center;
                                                        margin-right: 5px;
            
                                                        button{
                                                            align-items: center;
                                                            background-color: #ca2500;
                                                            border: none;
                                                            border-radius: 5px;
                                                            color: #ffffff;
                                                            display: flex;
                                                            justify-content: center;
                                                            height: fit-content;
                                                            padding: 5px;
                                                            transition: background-color 0.5s ease;
                                    
                                                            &:hover {
                                                                background-color: #a01d00;
                                                                cursor: pointer;
                                                            }
            
                                                            img{
                                                                height: 10px;
                                                                width: 10px;
                                                            }
                                                        }
                                                    }
                                                }
                                                
                                                
                                                #container-body-project-content-comment-modal{
                                                    h6{
                                                        color: rgb(132, 132, 132);
                                                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                                        margin: 0px;
                                                    }
                                                }
                                            }
   
                                        }
                                        

                                        
                                    }

                                    #container-li-middle-project-content-comment{
                                        margin:2px;

                  
                                        
                                    }

                                    #container-li-bottom-project-content-comment{
                                        margin: 0px;

                                    }
                                }
                            }

                            #container-loading-spinner{
                                margin-top: 5px;
                                margin-bottom: 5px;
                            }
                        }
                    }

    
    
                }

                #container-bottom-project-contentc-comments-modal{
                    height: 30%;

                    #container-form-create-project-content-comment-modal{
                        /* padding-right: 0.5em; */
                        margin-top: 0.5em;
                        /* margin-bottom: 0.5em; */
    
                        #container-text-area-create-project-content-comment-modal{

                            textarea{
                                box-sizing: border-box;
                                margin-top: 0.5em;
                                width: 100%;
                                height: 15vh;
                                padding: 2px;
                                border-radius: 5px;
                                border: 1px solid #ccc;
                                box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                                font-family: inherit;
                                outline: none;
                                font-size: 12px;
                            }

                            textarea:focus {
                                border-color: rgb(0, 0, 0); /* Altera a cor da borda ao focar */
                            }
                        }
    
                        #container-button-create-project-content-comment-modal{
                            display: flex;
                            width: 100%;
                            justify-content: end;
                            margin-top: 0.5em;


                            button {
                                margin-top: 0.5em;
                                color: white;
                                background-color: rgb(70, 70, 70);
                                border-radius: 5px;
                                border: none;
                                height: fit-content;
                                width: fit-content;
                                align-items: center;
                                justify-content: center;
                                padding: 10px;
                                cursor: pointer;
                                transition: background-color 0.3s, box-shadow 0.3s;
                            }

                            button:hover {
                                background-color: rgb(0, 0, 0);
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        
            

            
        }
    }
    
}