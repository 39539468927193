#container-landing-page{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    background-color: transparent;
    justify-content: center;
    overflow: hidden;

    #session-1{
        display: flex;
        width: 100%;
        height: fit-content;
        justify-content: center;

        #container-session-1-sub-1{
            display: flex;
            flex-direction: row;
            background-color: aliceblue;
            border-radius: 20px;
            justify-content: space-between;
            border: 4px solid brown;
            padding: 1em;
            width: fit-content;
            margin: 50px 100px 0px 100px;
            align-items: center;

            #container-text-session-1-sub-1{
                display: flex;
                flex-direction: column;
                margin-left: 50px;
                justify-content: space-between;
                padding: 1em 0em 1em 0em;

                @media(max-width: 800px){
                    margin-left: 20px;
                }

                @media(max-width: 400px){
                    margin-left: 0px;
                }

                #text-top-session-1-sub-1{
                    h1{
                        margin-left: 500px;
                        color: brown;
                        margin: 0px 0px 5px 0px;
                        text-wrap: nowrap;

                        @media (max-width: 900px) {
                            font-size: 28px;
                        }

                        @media (max-width: 850px) {
                            font-size: 22px;
                        }

                        @media (max-width: 800px) {
                            font-size: 20px;
                        }

                        @media (max-width: 500px) {
                            font-size: 18px;
                        }

                        @media (max-width: 450px) {
                            font-size: 16px;
                        }

                        @media (max-width: 400px) {
                            font-size: 14px;
                        }
                    }
    
                    h2{
                        margin: 5px 0px 0px 0px;

                        @media (max-width: 900px) {
                            font-size: 28px;
                        }

                        @media (max-width: 850px) {
                            font-size: 22px;
                        }

                        @media (max-width: 800px) {
                            font-size: 20px;
                        }

                        @media (max-width: 500px) {
                            font-size: 18px;
                        }

                        @media (max-width: 450px) {
                            font-size: 16px;
                        }

                        @media (max-width: 400px) {
                            font-size: 14px;
                        }
                    }
                }

                h3{
                    font-weight: normal;

                    @media (max-width: 900px) {
                        font-size: 22px;
                    }

                    @media (max-width: 850px) {
                        font-size: 18px;
                    }

                    @media (max-width: 800px) {
                        font-size: 14px;
                    }

                    @media (max-width: 500px) {
                        font-size: 12px;
                    }

                    @media (max-width: 450px) {
                        font-size: 10px;
                    }

                    @media (max-width: 400px) {
                        font-size: 8px;
                    }
                }
            }

            img{
                height: 200px;
                width: 240px;
                margin-right: 50px;
                margin-left: 20px;

                @media (max-width: 900px) {
                    height: 150px;
                    width: 190px;
                }

                @media (max-width: 850px) {
                    height: 120px;
                    width: 160px;
                }

                @media (max-width: 800px) {
                    height: 100px;
                    width: 140px;
                }

                @media(max-width: 800px){
                    margin-right: 20px;
                }

                @media (max-width: 400px) {
                    height: 80px;
                    width: 120px;
                    margin-right: 0px;
                }
            }
        }
    }

    #session-2{
        display: flex;
        height: fit-content;
        width: 100%;
        background-color: transparent;
        margin-top: 30px;

        #container-cases{
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 100%;
            box-sizing: border-box;
            
            #container-case-1{
                display: flex;
                align-items: end;
                height: 300px;
                width: 100%;
                background-color: transparent;

                @media(max-width: 800px){
                    height: 250px;
                }

                @media(max-width: 700px){
                    height: 200px;
                }

                @media(max-width: 700px){
                    height: 150px;
                }

                @media(max-width: 550px){
                    height: 120px;
                }

                #container-bar-text-pic{
                    display: flex;
                    background-color: rgb(165, 42, 42);
                    height: 240px;
                    justify-content: center;
                    width: 100%;
                    box-sizing: border-box;

                    @media (max-width: 800px){
                        height: 190px;
                    }

                    @media (max-width: 700px){
                        height: 150px;
                    }

                    @media (max-width: 550px){
                        height: 100px;
                    }

                    #container-case-text{
                        display:flex;
                        height: fit-content;
                        padding: 1em;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        margin-right: 200px;

                        @media (max-width: 1100px){
                            margin-right: 100px;
                        }

                        @media (max-width: 1000px){
                            margin-right: 50px;
                        }

                        @media (max-width: 950px){
                            margin: 0px;
                        }

                        @media (max-width: 700px){
                            padding: 0.8em;
                        }
                        
                        #container-depoiment{
                            border: 4px solid white;
                            padding: 1em;
                            box-sizing: border-box;
                            border-radius: 20px;

                            @media (max-width: 700px){
                                border: 2px solid white;
                                padding: 0.8em;
                                border-radius: 10px;
                            }

                            h4{
                                font-size: 20px;
                                
                                @media (max-width: 950px) {
                                    font-size: 14px;
                                }
            
                                @media (max-width: 700px) {
                                    font-size: 10px;
                                    width: 250px;
                                }
            
                                @media (max-width: 650px) {
                                    width: 300px;
                                }
            
                                @media (max-width: 550px) {
                                    width: 200px;
                                    font-size: 8px;
                                }
            
                                @media (max-width: 400px) {
                                    font-size: 8px;
                                    width: 150px;
                                }
                            }

                            #h4-depoiment{
                                color: white;
                                width: 350px;
                                margin: 0px;
                                font-size: 20px;
                                font-weight: bold;

                                @media (max-width: 950px){
                                    margin: 0px;
                                    font-size: 14px;
                                }

                                @media (max-width: 700px){
                                    margin: 0px;
                                    font-size: 10px;
                                }

                                @media (max-width: 650px){
                                    width: 300px;
                                }

                                @media (max-width: 550px){
                                    width: 200px;
                                    font-size: 8px;
                                }

                                @media (max-width: 400px){
                                    font-size: 8px;
                                    width: 150px;
                                }
                            }

                            #h4-deponent-name{
                                color: white;
                                margin-bottom: 0px;
                                margin-top: 10px;
                                font-weight: bold;

                                @media (max-width: 550px){
                                    margin-top: 5px;
                                }

                            }
                        }
                    }

                    img{
                        position: relative;
                        height: 300px;
                        width: 300px;
                        left: 10px;
                        top: -60px;

                        @media (max-width: 800px){
                            height: 250px;
                            width: 250px;
                        }

                        @media (max-width: 700px) {
                            height: 210px;
                            width: 210px;
                        }

                        @media (max-width: 600px) {
                            height: 150px;
                            width: 150px;
                            top: -0px;
                        }

                        @media (max-width: 550px){
                            top: -30px;
                            height: 130px;
                            width: 130px;
                        }

                        @media (max-width: 350px){
                            top: -0px;
                            height: 100px;
                            width: 100px;
                        }
                        
                    }
                }
            }

            #container-case-2{
                display: flex;
                height: 200px;
                width: 100%;
                background-color: transparent;

                @media (max-width: 800px){
                    height: 190px;
                }
            
                @media (max-width: 700px) {
                    height: 150px;
                }
        
                @media (max-width: 550px) {
                    height: 100px;
                }

                #container-bar-text-pic{
                    display: flex;
                    background-color: rgb(165, 42, 42);
                    height: 200px;
                    justify-content: center;
                    width: 100%;
                    box-sizing: border-box;
                    
                    @media (max-width: 800px){
                        height: 190px;
                    }

                    @media (max-width: 700px){
                        height: 150px;
                    }

                    @media (max-width: 550px){
                        height: 100px;
                    }

                    #container-case-text{
                        display:flex;
                        height: fit-content;
                        padding: 1em;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        margin-right: 200px;

                        
                        @media (max-width: 1100px){
                            margin-right: 100px;
                        }

                        @media (max-width: 1000px){
                            margin-right: 50px;
                        }

                        @media (max-width: 950px){
                            margin: 0px;
                        }

                        @media (max-width: 700px){
                            padding: 0.8em;
                        }

                        #container-depoiment{
                            border: 4px solid white;
                            padding: 1em;
                            box-sizing: border-box;
                            border-radius: 20px;
                            
                            @media (max-width: 700px){
                                border: 2px solid white;
                                padding: 0.8em;
                                border-radius: 10px;
                            }

                            h4{
                                font-size: 20px;
                                
                                @media (max-width: 950px) {
                                    font-size: 14px;
                                }
            
                                @media (max-width: 700px) {
                                    font-size: 10px;
                                    width: 250px;
                                }
            
                                @media (max-width: 650px) {
                                    width: 300px;
                                }
            
                                @media (max-width: 550px) {
                                    width: 200px;
                                    font-size: 8px;
                                }
            
                                @media (max-width: 400px) {
                                    font-size: 8px;
                                    width: 150px;
                                }
                            }

                            #h4-depoiment{
                                color: white;
                                font-weight: bold;

                                @media (max-width: 950px){
                                    margin: 0px;
                                }
                            }

                            #h4-deponent-name{
                                color: white;
                                margin: 0px;
                                margin-top: 10px;
                                font-weight: bold;

                                @media (max-width: 550px){
                                    margin-top: 5px;
                                }
                            }
                        }
                    }

                    img{
                        position: relative;
                        height: 260px;
                        width: 210px;
                        left: 10px;
                        top: -60px;
                        transform: scaleX(-1);

                        
                        @media (max-width: 800px) {
                            height: 250px;
                            width: 200px;
                        }

                        @media (max-width: 700px) {
                            height: 210px;
                            width: 160px;
                        }

                        @media (max-width: 600px) {
                            height: 150px;
                            width: 120px;
                            top: 0px;
                        }

                        @media (max-width: 550px) {
                            height: 110px;
                            width: 90px;
                            top: -10px;
                        }

                        @media (max-width: 350px){
                            top: 20px;
                            height: 80px;
                            width: 60px;
                        }
                    }
                }
            }

            #container-case-3 {
                display: flex;
                height: 200px;
                width: 100%;
                background-color: transparent;

                @media (max-width: 800px){
                    height: 190px;
                }
            
                @media (max-width: 700px) {
                    height: 150px;
                }
        
                @media (max-width: 550px) {
                    height: 100px;
                }
            
                #container-bar-text-pic {
                    display: flex;
                    background-color: rgb(165, 42, 42);
                    height: 200px;
                    justify-content: center;
                    width: 100%;
                    box-sizing: border-box;
            
                    @media (max-width: 800px) {
                        height: 190px;
                    }
            
                    @media (max-width: 700px) {
                        height: 150px;
                    }
            
                    @media (max-width: 550px) {
                        height: 100px;
                    }
            
                    #container-case-text {
                        display: flex;
                        height: fit-content;
                        padding: 1em;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        margin-right: 200px;
            
                        @media (max-width: 1100px) {
                            margin-right: 100px;
                        }
            
                        @media (max-width: 1000px) {
                            margin-right: 50px;
                        }
            
                        @media (max-width: 950px) {
                            margin: 0px;
                        }
            
                        @media (max-width: 700px) {
                            padding: 0.8em;
                        }
            
                        #container-depoiment {
                            border: 4px solid white;
                            padding: 1em;
                            box-sizing: border-box;
                            border-radius: 20px;
            
                            @media (max-width: 700px) {
                                border: 2px solid white;
                                padding: 0.8em;
                                border-radius: 10px;
                            }

                            h4{
                                font-size: 20px;
                                
                                @media (max-width: 950px) {
                                    font-size: 14px;
                                }
            
                                @media (max-width: 700px) {
                                    font-size: 10px;
                                    width: 250px;
                                }
            
                                @media (max-width: 650px) {
                                    width: 300px;
                                }
            
                                @media (max-width: 550px) {
                                    width: 200px;
                                    font-size: 8px;
                                }
            
                                @media (max-width: 400px) {
                                    font-size: 8px;
                                    width: 150px;
                                }
                            }
            
                            #h4-depoiment {
                                color: white;
                                /* width: 350px; */
                                margin: 0px;
                                font-weight: bold;
                            }
            
                            #h4-deponent-name {
                                color: white;
                                /* width: 350px; */
                                margin: 0px;
                                margin-top: 10px;
                                font-weight: bold;
            
                                @media (max-width: 550px) {
                                    margin-top: 5px;
                                }
            
                            }
                        }
                    }
            
                    img {
                        position: relative;
                        height: 260px;
                        width: 300px;
                        left: 10px;
                        top: -60px;
                        transform: scaleX(-1);
            
                        @media (max-width: 800px) {
                            height: 250px;
                            width: 290px;
                        }
            
                        @media (max-width: 700px) {
                            height: 210px;
                            width: 230px;
                        }
            
                        @media (max-width: 600px) {
                            height: 150px;
                            width: 170px;
                            top: 0px;
                        }
            
                        @media (max-width: 550px) {
                            height: 110px;
                            width: 120px;
                            top: -10px;
                        }
            
                        @media (max-width: 350px) {
                            top: 20px;
                            height: 80px;
                            width: 100px;
                        }
                    }
                }
            }
            


        }
    }

    #session-3{
        display: flex;
        background-color: black;
        height: 150px;
        justify-content: center;
        align-items: center;
        
        h4{
            width: fit-content;
            color: white;
            font-size: 60px;

            @media (max-width: 950px){
                font-size: 58px;
            }

            @media (max-width: 900px){
                font-size: 52px;
            }

            @media (max-width:800px){
                font-size: 48px;
            }

            @media (max-width: 700px){
                font-size: 40px;
            }

            @media (max-width: 600px){
                font-size: 30px;
            }

            @media (max-width: 450px){
                font-size: 24px;
            }

            @media (max-width: 400px){
                font-size: 20px;
            }
        }
    }

    #session-4{
        display: flex;
        flex-direction: column;
        background-color: black;
        height: fit-content;
        justify-content: center;
        align-items: center;
        padding: 1em;

        #tutorial-header{
            display: flex;

            h4{
                width: fit-content;
                color: white;
                font-size: 40px;
                margin: 0px;


                @media (max-width: 950px){
                    font-size: 40px;
                }

                @media (max-width: 900px){
                    font-size: 38px;
                }

                @media (max-width:800px){
                    font-size: 36px;
                }

                @media (max-width: 700px){
                    font-size: 34px;
                }

                @media (max-width: 600px){
                    font-size: 30px;
                }

                @media (max-width: 450px){
                    font-size: 24px;
                }

                @media (max-width: 400px){
                    font-size: 20px;
                }
            }
    
            button{
                margin-left: 20px;
                background-color: transparent;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                height: fit-content;
                width: fit-content;
            
                #arrow-icon-tutorial-show-false{
                    transform: scaleY(1);
                }
    
                #arrow-icon-tutorial-show-true{
                    transform: scaleY(-1);
                }

                img{
                    width: 44px;
                    height: 44px;

                    @media (max-width: 950px){
                        width: 40px;
                        height: 40px;
                    }

                    @media (max-width: 900px){
                        width: 36px;
                        height: 36px;
                    }

                    @media (max-width: 700px){
                        width: 36px;
                        height: 36px;
                    }

                    @media (max-width: 600px){
                        width: 34px;
                        height: 34px;
                    }

                    @media (max-width: 450px){
                        width: 28px;
                        height: 28px;
                    }

                    @media (max-width: 400px){
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        #container-tutorial{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 1em;
            box-sizing: border-box;
            height: 100%;
            background-color: white;

            #tutorial-title{
                display: flex;
                width: 100%;
                justify-content: center;
                font-size: 30px;
                margin: 5px 0px 5px 0px;

                h2{
                    width: fit-content;

                    @media(max-width: 1000px){
                        font-size: 28px;
                    }
    
                    @media(max-width: 900px){
                        font-size: 24px;
                    }
    
                    @media(max-width: 700px){
                        font-size: 20px;
                    }

                    @media(max-width: 450px){
                        font-size: 14px;
                    }
                }
            }

            #tutorial-step-1{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                img{

                    @media(max-width: 1000px){
                        width: 400px;
                        height: 420px;
                    }

                    @media(max-width: 950px){
                        width: 350px;
                        height: 370px;
                    }

                    @media(max-width: 900px){
                        width: 300px;
                        height: 320px;
                    }

                    @media(max-width: 600px){
                        width: 250px;
                        height: 270px;
                    }

                    @media(max-width: 550px){
                        width: 200px;
                        height: 220px;
                    }

                    @media(max-width: 450px){
                        width: 150px;
                        height: 170px;
                    }
                }

                #tutorial-instruction{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    h4{
                        font-size: 30px;
                        margin-left: 50px;

                        @media(max-width: 1000px){
                            font-size: 24px;
                            margin-left: 30px;
                        }

                        @media(max-width: 950px){
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        @media(max-width: 900px){
                            font-size: 16px;
                            margin-left: 18px;
                        }

                        @media(max-width: 500px){
                            font-size: 14px;
                            margin-left: 14px;
                        }

                        @media(max-width: 450px){
                            font-size: 10px;
                            margin-left: 10px;
                        }

                        @media(max-width: 350px){
                            font-size: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            #tutorial-step-2{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                img{

                    @media(max-width: 1000px){
                        width: 600px;
                        height: 400px;
                    }

                    @media(max-width: 950px){
                        width: 400px;
                        height: 200px;
                    }

                    @media(max-width: 900px){
                        width: 320px;
                        height: 220px;
                    }

                    @media(max-width: 600px){
                        width: 370px;
                        height: 250px;
                    }

                    @media(max-width: 550px){
                        width: 220px;
                        height: 120px;
                    }
                }

                #tutorial-instruction{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    h4{
                        font-size: 30px;
                        margin-left: 50px;

                        @media(max-width: 1000px){
                            font-size: 24px;
                            margin-left: 30px;
                        }

                        @media(max-width: 950px){
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        @media(max-width: 900px){
                            font-size: 16px;
                            margin-left: 18px;
                        }

                        @media(max-width: 500px){
                            font-size: 14px;
                            margin-left: 14px;
                        }

                        @media(max-width: 450px){
                            font-size: 10px;
                            margin-left: 10px;
                        }

                        @media(max-width: 350px){
                            font-size: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            #tutorial-step-3{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                img{

                    @media(max-width: 1000px){
                        width: 600px;
                        height: 320px;
                    }

                    @media(max-width: 950px){
                        width: 400px;
                        height: 220px;
                    }

                    @media(max-width: 600px){
                        width: 280px;
                        height: 100px;
                    }
                }

                #tutorial-instruction{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    h4{
                        font-size: 30px;
                        margin-left: 50px;

                        @media(max-width: 1000px){
                            font-size: 24px;
                            margin-left: 30px;
                        }

                        @media(max-width: 950px){
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        @media(max-width: 900px){
                            font-size: 16px;
                            margin-left: 18px;
                        }

                        @media(max-width: 500px){
                            font-size: 14px;
                            margin-left: 14px;
                        }

                        @media(max-width: 450px){
                            font-size: 10px;
                            margin-left: 10px;
                        }

                        @media(max-width: 350px){
                            font-size: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            #tutorial-step-4{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                img{

                    @media(max-width: 1000px){
                        width: 600px;
                        height: 320px;
                    }

                    @media(max-width: 950px){
                        width: 400px;
                        height: 220px;
                    }

                    @media(max-width: 600px){
                        width: 280px;
                        height: 100px;
                    }

                    @media(max-width: 350px){
                        width: 260px;
                        height: 80px;
                    }
                }

                #tutorial-instruction{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    h4{
                        font-size: 30px;
                        margin-left: 50px;

                        @media(max-width: 1000px){
                            font-size: 24px;
                            margin-left: 30px;
                        }

                        @media(max-width: 950px){
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        @media(max-width: 900px){
                            font-size: 16px;
                            margin-left: 18px;
                        }

                        @media(max-width: 500px){
                            font-size: 14px;
                            margin-left: 14px;
                        }

                        @media(max-width: 450px){
                            font-size: 10px;
                            margin-left: 10px;
                        }

                        @media(max-width: 350px){
                            font-size: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            #tutorial-step-5{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                img{

                    @media(max-width: 1000px){
                        width: 600px;
                        height: 320px;
                    }

                    @media(max-width: 950px){
                        width: 400px;
                        height: 220px;
                    }

                    @media(max-width: 600px){
                        width: 280px;
                        height: 100px;
                    }

                    @media(max-width: 350px){
                        width: 260px;
                        height: 80px;
                    }
                }

                #tutorial-instruction{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    h4{
                        font-size: 30px;
                        margin-left: 50px;

                        @media(max-width: 1000px){
                            font-size: 24px;
                            margin-left: 30px;
                        }

                        @media(max-width: 950px){
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        @media(max-width: 900px){
                            font-size: 16px;
                            margin-left: 18px;
                        }

                        @media(max-width: 500px){
                            font-size: 14px;
                            margin-left: 14px;
                        }

                        @media(max-width: 450px){
                            font-size: 10px;
                            margin-left: 10px;
                        }

                        @media(max-width: 350px){
                            font-size: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }


    }

    #session-5{
        background-color: white;
        padding: 1em;

        #container-logo-button-sign-in{
            display: flex;
            border: 4px solid brown;
            border-radius: 40px;
            justify-content: space-around;
            align-items: center;

            @media(max-width: 700px){
                border-radius: 20px;
            }
            
            img{
                padding: 1em;
                height: 200px;
                width: 250px;

                @media(max-width: 1000px){
                    height: 180px;
                    width: 230px;
                }

                @media(max-width: 900px){
                    height: 140px;
                    width: 190px;
                }

                @media(max-width: 700px){
                    height: 120px;
                    width: 170px;
                }

                @media(max-width: 450px){
                    height: 100px;
                    width: 150px;
                }

                @media(max-width: 400px){
                    height: 80px;
                    width: 120px;
                }
            }

            button{
                border: 4px solid brown;
                height: fit-content;
                cursor: pointer;
                background-color: transparent;
                color: brown;
                font-size: 30px;
                font-weight: bold;
                border-radius: 10px;
                padding: 0.5em;

                @media(max-width: 1000px){
                    font-size: 24px;
                }

                @media(max-width: 900px){
                    font-size: 20px;
                }

                @media(max-width: 700px){
                    font-size: 18px;
                }

                @media(max-width: 500px){
                    font-size: 14px;
                    border: 2px solid brown;
                    border-radius: 5px;
                }
                
                @media(max-width: 400px){
                    font-size: 10px;
                }
            }

            button:hover{
                background-color: darkred;
                color: white;
                border-color: darkred;
            }
        }
    }

    #lading-page-bottom{
        color: white;
        background-color: black;
        padding: 1em;
        font-size: 16px;

        h5{
            margin: 0px 0px 10px 0px;

            @media(max-width: 1000px){
                font-size: 14px;
            }

            @media(max-width: 900px){
                font-size: 12px;
            }

            @media(max-width: 700px){
                font-size: 10px;
            }

            @media(max-width: 500px){
                font-size: 8px;
            }
            
            @media(max-width: 400px){
                font-size: 6px;
            }
        }
        
        #container-contact{
            display: flex;
            margin-bottom: 10px;
            align-items: center;

            img{
                height: 20px;
                width: 20px;

                @media(max-width: 1000px){
                    height: 18px;
                    width: 18px;
                }

                @media(max-width: 900px){
                    height: 16px;
                    width: 16px;
                }

                @media(max-width: 700px){
                    height: 14px;
                    width: 14px;
                }

                @media(max-width: 500px){
                    height: 12px;
                    width: 12px;
                }
                
                @media(max-width: 400px){
                    height: 10px;
                    width: 10px;
                }
            }

            h6{
                margin: 0px 0px 0px 10px;

                @media(max-width: 1000px){
                    font-size: 12px;
                }

                @media(max-width: 900px){
                    font-size: 10px;
                }

                @media(max-width: 700px){
                    font-size: 8px;
                }

                @media(max-width: 500px){
                    font-size: 6px;
                }
                
                @media(max-width: 400px){
                    font-size: 7px;
                }
            }
        }
    }
}