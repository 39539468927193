#container-yes-no-dialog-modal{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 1001;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-screen-transparent-yes-no-diolog{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#container-modal-yes-no{
    border-radius: 10px;
    max-width: 60vw;
    height: fit-content;
    margin: 0 auto;
    padding: 1.5em 1.5em;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    
    
    @media (min-width: 700px) {
        width: 40vw;
    }
}

#container-message-yes-no-modal{
    width: 100%;
    margin-bottom: 10px;

    p {
        margin: 0px;
        text-align: center;
        text-wrap:wrap;

        @media (max-width: 400px) {
            font-size: 12px;
        }
    }
}

#container-yes-no-diolog-modal-bottom{
    display: flex;
    width: 100%;
    justify-content: center;
}

#container-yes-no-diolog-modal-buttons{
    display: flex;

    #button-yes-diolog-yes-no-modal{
        font-weight: bold;
        font-size: 18px;
        background-color: rgb(255, 0, 0);
        border: none;
        border-radius: 5px;
        width: 60px;
        height: 30px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin: 5px;
    }

    #button-yes-diolog-yes-no-modal:hover{
        background-color: rgb(198, 1, 1);
    }



    #button-no-diolog-yes-no-modal{
        font-weight: bold;
        font-size: 18px;
        background-color: rgb(70, 70, 70);
        border: none;
        border-radius: 5px;
        width: 60px;
        height: 30px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin: 5px;
    }

    #button-no-diolog-yes-no-modal:hover{
        background-color: rgb(255, 255, 255);
        border: solid 2px rgb(70, 70, 70);
        color: rgb(70, 70, 70);
    }
}