#project-name-field-project-management-route {
    width: 100%;  /* Largura maior */
    border: 1px solid #ccc;  /* Borda cinza clara */
    border-radius: 8px;  /* Bordas levemente arredondadas */
    padding: 12px 18px;  /* Espaçamento interno aumentado */
    font-size: 16px;  /* Tamanho de fonte moderno */
    transition: border-color 0.3s, box-shadow 0.3s;  /* Transições suaves */
    outline: none;  /* Remover outline padrão */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Sombra sutil para profundidade */
    background-color: #fff;  /* Fundo branco */
    color: #000;  /* Texto preto */
}

#project-name-field-project-management-route:focus {
    border-color: #000;  /* Borda preta no foco */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Sombra mais pronunciada no foco */
}

#project-name-field-project-management-route::placeholder {
    color: #888;  /* Cor do placeholder cinza */
    opacity: 1;  /* Garantir que a cor se aplique em todos os navegadores */
}

#project-name-field-project-management-route:hover {
    border-color: #000;  /* Mudar a cor da borda ao passar o mouse */
}
