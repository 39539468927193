#container-page-group {
    display: flex;
    height: 100em;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-group-left {
    background-color: rgb(245, 245, 245);
    flex: 0 0 10%;
}

#container-group-center {
    display: flex;
    background-color: rgb(245, 245, 245);
    flex: 0 0 80%;
    flex-direction: column;
    height: 100em;
    z-index: 2;
    width: 50%;
    align-items: center;
}

#container-group-right {
    background-color: rgb(245, 245, 245);
    flex: 0 0 10%;
}

#container-header-group {
    margin-top: 80px;
    background-color: rgb(245, 245, 245);
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

#container-header-group #container-group-info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0;

    #container-pre-group-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        pre{
            font-size: 10px;
        }
    }
}

#h1-group-name {
    margin: 0px;
    font-size: 20px;

    @media (max-width: 500px) {
        font-size: 18px;
        
    }
}



#pre-group-description {
    margin: 10px 0px 10px 0px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 100%;
    height: 50px;
    overflow: hidden;
}

#retract-show-button img{
    height: 20px;
    transform: rotate(180deg);
}

#retract-show-button{
    background-color: transparent;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
}

#retract-show-button:hover {
    background-color: #eeeeee;
}

#container-create-post-component{
    width: 100%;
}

#container-ul-posts{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2px;
    margin: 0;
    border-radius: 5px;
    white-space: nowrap;
    overflow-y: auto;
    background-color: #fafafa;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 596px;
  
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  
    &::-webkit-scrollbar {
      height: 6px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
      transition: background-color 0.2s, height 0.2s;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
      height: 8px;
    }
}

#container-posts-component{
    display: flex;
    position: relative;
    margin-top: 10px;
    width: 100%;
}

