.container-wave-surfer-project {
    display:flex;
    flex-direction: row;
    width: fit-content;
    height: 100%;
    margin: 2px;
    user-select: none;

        .container-show-startIn-endsIn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: sticky;
            flex-direction: column;
            width: fit-content;
            padding: 5px;
            right: 10px;
            z-index: 1;
            opacity: 1;
            font-size: 14px;
            color: #333; 
            border: 1px solid #afafaf;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            background-color: #f5f5f5;
            user-select: none;

            @media (max-width: 500px) {
                padding: 5px;
            }
        }

        &:hover .container-show-startIn-endsIn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: sticky;
            flex-direction: column;
            width: fit-content;
            right: 125px;
            z-index: 1;
            opacity: 1;
            font-size: 14px;
            color: #333; 
            border: 1px solid #afafaf;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            background-color: #f5f5f5;
            user-select: none;
        }

        .container-starts-in-formated {
            font-size: 10px;
            border: 1px solid #ccc;
            background-color: #f2f2f2;
            text-align: center;
            padding: 0px;
            user-select: none;
            color: #888;
            user-select: none;

            @media (max-width:500px) {
                font-size: 8px;
            }
        }

        .label-starts-in {
            font-size: 10px;
            margin-bottom: 2px;
            color:black;    
            user-select: none;

            @media (max-width:500px) {
                font-size: 8px;
            }
        }

        /* #container-change-usability-useless-contents {
            display: none;
        } */

        #container-change-usability-useless-contents{
            display: flex;
            flex-direction: row;
            margin-right: 10px;
        }

        

        #container-change-usability-comments-info {
            display: none;
        }
        
        &:hover #container-change-usability-comments-info {
            display: flex;
            right: 2px;
            user-select: none;
            width: 110px;
            position: sticky;
            flex-direction: row;
            right: 10px;
            align-items: center;
        }
  
        #change-usabilty-button{
            display: flex;
            background-color: rgba(0, 0, 0, 0.74);
            border-radius: 5px;
            border: none;
            height: 30px;
            width: 30px;
            justify-content: center;
            align-items: center;
            margin-right: 5px;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        
        #change-usabilty-button:hover {
            background-color: rgb(0, 0, 0);
            border-radius: 5px;
            border: none;
            justify-content: center;
            background-position: center;
            background-size: cover;
            cursor: pointer;
        }

        #container-button-comment-info{
            display: flex;
            flex-direction: column;
        }

        #button-comment-project-content{
            display: flex;
            background-color: rgba(0, 0, 0, 0.74);
            border-radius: 5px;
            border: none;
            height: 30px;
            width: 30px;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.5px;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }

        #button-comment-project-content:hover {
            background-color: rgb(0, 0, 0);
            cursor: pointer;
        }

        #button-project-content-more-info{
            display: flex;
            background-color: rgba(0, 0, 0, 0.74);
            border-radius: 5px;
            border: none;
            height: 30px;
            width: 30px;
            justify-content: center;
            align-items: center;
            margin-top: 2.5px;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }

        #button-project-content-more-info:hover {
            background-color: rgb(0, 0, 0);
            cursor: pointer;
        }
}

#container-change-usability-comments-info{
    display: flex;
    flex-direction: column;
}

/* .container-show-startIn-endsIn {
    display: flex;
    opacity: 1;
    margin-left: 5px;
    padding-bottom: 2px;
    user-select: none;

    @media (max-width: 500px) {
        padding: 2px;
    }
} */

.container-controller-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #afafaf;
    background-color: #f5f5f5;
    height: 100%;
    border-radius: 5px;
    user-select: none;
}

.container-checkBox-play-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
}

.checkBox-container {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    user-select: none;
    div{
      display: flex;
      align-items: center;
    }
}

.container-time-progress {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: flex-end;
    height: fit-content;
    padding: 5px;
    user-select: none;
}

.wave-project-content{
    display: flex;
    width: 100%;
    user-select: none;
}

.conainer-wave-slider{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    user-select: none;
}

.container-wave{
    display: flex;
    border-radius: 5px;
    margin-left: 1px;
    border: 1px solid #afafaf;
    background-color: #f5f5f5;
    user-select: none;
}

.wave-surfer{
    display: flex;
    height: 100%;
    user-select: none;
}

.wave-surfer div{
    display: flex;
    user-select: none;
}

.container-wave-slider{
    display: flex;
    flex-direction: column;
}

.container-slider-bar{
    display:flex;
    position: relative;
    height: 20%;
    border-radius: 0px 0px 5px 5px;
    align-items: center;
    bottom: 0;
    border: 1px solid #afafaf;
    background-color: #f5f5f5;
    margin: 1px 0px 1px 1px;
    user-select: none;
}

.wave-slider{
    padding: 0px;
    margin: 1px -2px 0px -1px;
    height: 100%;
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    user-select: none;
}

.container-time-from-start{
    display: flex;
    width: fit-content;
    margin: 0px;

    h5{
        display: flex;
        margin: 0px;
    }
}

.container-time-to-end{
    display: flex;
    width: fit-content;

    h5{
        margin: 0px;
    }
  }

#text-remove-content-from-project {
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 400px) {
        font-size: 15px;
    }
}

#container-project-content-info-modal-component{
    display: flex;
    z-index: 10000;
}