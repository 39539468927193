#container-users-search{
    
    #container-nothing-to-show-users-search{
        display: flex;
        position: relative;
        top: 50px;
        justify-content: center;

        h2{
            color: rgb(129, 129, 129);
            width: fit-content;

            @media (max-width: 800px) {
                font-size: 18px;
            }

            @media (max-width: 600px) {
                font-size: 12px;
            }
        }
    }

    ul{
        padding: 0px;
        list-style: none;
    
        #container-li-search-user {
            border: 1px solid #afafaf;
            background-color: #f5f5f5;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: background-color 0.1s;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 2px;
    
            li{
                padding: 5px;
                /* box-sizing:content-box; */
                
                #container-top-li-search-user{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
    
                    #container-user-info-li-search{
                        display: flex;
    
                        img{
                            height: 30px;
                            border-radius: 100%;
                        }
    
                        a{
                            margin: 0px 0px 0px 10px;
                            align-content: center;
                            user-select: none;
                            cursor: pointer;
                            font-weight: bold;
    
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
    
    
                #container-middle-li-search-user{
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
    
                    #container-about-user-search{
                        display: flex;
                        background-color: rgb(120, 120, 120);
                        width: 100%;
                        border-radius: 5px;
                        color: rgb(255, 255, 255);
                        font-weight: bold;
                        padding: 5px;
                        margin: 0px;
                        box-sizing: border-box;
                        overflow-y: scroll;
                        scrollbar-width: thin;
                        scrollbar-color: #888 transparent;
                      
                          &::-webkit-scrollbar {
                              height: 6px;
                          }
                      
                          &::-webkit-scrollbar-thumb {
                              background-color: #888;
                              border-radius: 6px;
                              transition: background-color 0.2s, height 0.2s;
                          }
                      
                          &::-webkit-scrollbar-thumb:hover {
                              background-color: #555;
                              height: 8px;
                          }
                      
                        pre {
                          margin: 0;
                          font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                          width: 100%;
                          max-height: calc(1em * 4);
                          cursor: default;
                          min-height: 50px;
                          white-space: pre-wrap;
                          font-size: 12px;
                          word-wrap: break-word;
                      
                          @media (max-width: 500px) {
                            font-size: 12px;
                          }
                        }
                    }

                    #container-location-user-search{
                        display: flex;
                        margin-top: 5px;
                        justify-content: end;
                        font-size: 12px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-weight: bold;

                        @media(max-width: 800px){
                            font-size: 10px;
                        }
                    }
                  }
    
                #container-bottom-li-search-user{
                    #container-instruments-user-search{
                        
                        h4{
                            margin: 5px 0pc 5px 0px;

                            @media(max-width: 600px){
                                font-size: 12px;
                            }
                        }
    
                        ul{
                            display: flex;
                            align-items: center;
                            list-style: none;
                            flex-wrap: wrap;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-left: 0px;
    
                            li{
                                display: flex;
                                width: fit-content;
                                height: fit-content;
                                padding: 2px;
                                background-color:  rgb(0, 190, 0);
                                color: rgb(255, 255, 255);
                                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                border: solid 1px rgb(0, 190, 0);
                                margin: 2px;
                                border-radius: 5px;
                                white-space: nowrap;
                                user-select: none;
        
                                h6{
                                    margin: 0px;
                                    
                                    @media(max-width: 600px){
                                        font-size: 8px;
                                    }
                                }
                            }
                        }
                    }
    
                    #container-genres-user-search{
                        
                        h4{
                            margin: 5px 0pc 5px 0px;


                            @media(max-width: 600px){
                                font-size: 12px;
                            }
                        }
                        
                        ul{
                            
                            display: flex;
                            align-items: center;
                            list-style: none;
                            flex-wrap: wrap;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-left: 0px;
    
                            li{
                                display: flex;
                                width: fit-content;
                                height: fit-content;
                                padding: 2px;
                                background-color:  rgb(0, 190, 0);
                                color: rgb(255, 255, 255);
                                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                border: solid 1px rgb(0, 190, 0);
                                margin: 2px;
                                border-radius: 5px;
                                white-space: nowrap;
                                user-select: none;
        
                                h6{
                                    margin: 0px;

                                    @media(max-width: 600px){
                                        font-size: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
