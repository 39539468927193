#container-modal-delete-group-dialog{
    display: flex;
    position: absolute;
    background-color: #FFF;
    padding: 10px;
    z-index: 4;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    border: 1px solid #ddd;
    /* left: 0; */
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
    @media (max-width: 700px) {
        right: 0;
      }
}

#container-input-button-delete-group-dialog{
    display: flex;
    flex-direction: row;   
    margin-top: 10px;
}

#container-form-delete-group-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#container-form-delete-group-dialog label{
    height: fit-content;
    width: 200px;
}

#container-form-delete-group-dialog input{
    width: 150px;
    margin-right: 5px;
}