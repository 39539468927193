.container-wave-surfer {
    display: flex;
    /* position: relative; */
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    /* margin: 2px; */
    border: 1px solid #afafaf;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

.container-time-progress {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-end;
    height: fit-content;
    padding: 5px;;
}

.container-wave-top-project{
    display: flex;
    width: 100%;
    height: 100%;
}

.wave-surfer-project .div{
    height: 100%;
    background-color: blue;
}

.container-time-from-start{
    display: flex;
    width: fit-content;
}

.container-time-to-end{
    display: flex;
    width: fit-content;
  }