#container-page-contents{
  display: flex;
  height: fit-content;
  width: 100%;
  position: absolute;


  @media (max-width: 300px) {
    width: 300px;
  }
}

#container-form-contents {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: fit-content;

}

#container-contents-left{
  background-color: rgb(245, 245, 245);
  height: 150vh;
  width: 10vw;
}

#container-center-contents{
  display: flex;
  background-color: rgb(245, 245, 245);
  flex: 0 0 80%;
  flex-direction: column;
  align-items: center;
}

#container-title-player{
  width: 100%;
  z-index: 3;
}

#container-contents-right{
  background-color: rgb(245, 245, 245);
  height: 150vh;
  width: 10vw;
}

.container-letters-title{
  padding-top: 10px;
  padding-bottom: 10px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 20px ;
  margin-right: 5px;
  user-select: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.letter{
  display: flex;
  justify-content: center;
  align-items: center;
}

#my-contents-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: fit-content;
}

#container-title-my-contents{
  display: flex;
  height: fit-content;
}

#contents-container {
  display: flex;
  justify-content: center;
  align-items: start;
  width: 70vw;
  height: fit-content;
}

.no-contents-style {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(189, 189, 189);
}

#container-ul-my-contents {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2px;
  margin: 0;
  border-radius: 5px;
  white-space: nowrap;
  overflow-x: auto;
  background-color: #fafafa;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 450px;

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    transition: background-color 0.2s, height 0.2s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    height: 8px;
  }

  #container-loading-spineer{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


.loading-spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-audio-player-my-content {
  padding: none;
  margin: none;
}

.container-ul--my-contents ul {
  padding-left: 10px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}

.submit-button-container {
  flex-direction: row;
  display: flex;
  overflow: auto;
}

#li-my-contents {
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100%;

  #container-my-contents-buttons{
    display: flex;
    position: absolute;
    margin-top: 10px;
    z-index: 3;
    height: 70px;
    width: 0px;
    right: 50px;
  
    @media (max-width: 500px) {
      top: 0px;
      right: 40px;
    }

    #more-details-my-content {
      display: flex;
      background-color: rgb(70, 70, 70);
      border-radius: 5px;
      border: none;
      transition: background-color 0.3s, box-shadow 0.3s;
      cursor: pointer;
      height: 30px;
      width: 40px;
      justify-content: center;
      align-items: center;
      padding: 5px;
    
      @media (max-width: 500px) {
        height: 20px;
        width: 25px;
      }
  
      &:hover{
        background-color: rgb(0, 0, 0);
        box-shadow: none;
      }
  
      img {
        height: 20px;
        width: 20px;
      
        @media (max-width: 500px) {
          height: 10px;
          width: 10px;
        }
      }
      }
    }
}

#container-title-content{
  display: flex;
  position: sticky;
  height: 1px;
  left: 120px;
  z-index: 3;
}

#container-title-content-h5 {
  display: flex;
  position: relative;
  padding: 0;
  margin: 0;
  top: 10px;
  left: 10px;
  z-index: 3;
  height: fit-content;
  width: fit-content;
  background-color: black;
  opacity: 0.6;
  color: white;
  border-radius: 2px;
  padding: 2px 5px 2px 5px;
}

.title-content-h5 {
  display: flex;
  padding: none;
  margin: 0;
}

#ul-my-contents {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px 0px 0px 5px;
}

.no-contents-yet {
  text-align: center;
  color: rgb(85, 85, 85);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
}

.container-wave-surfer-player {
  display: flex;
  width: 100%;
  position: relative;
  left: -5px;
  z-index: 3px;
    box-sizing: border-box;
}

#container-instrument-name{
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 45px;
  background-color: black;
  align-content: center;
  padding-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 5px;
  box-sizing: border-box;
}

#container-instrument-name h5 {
  font-size: 10px;
  display: flex;
  margin: 0;
  position: relative;
  transform: rotate(-90deg);
  text-align: center;
  justify-content: center;
  align-items:center;
  white-space: pre-wrap;
  height: 0px;
  width: 0px;
  color: white;
  border-radius: 2px;
  padding: 5px 5px 2px 5px;
  user-select: none;
}