#container-message-box {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#container-message-box #container-ul-messages-box-components {
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
    padding: 0;

}

#container-message-box #container-ul-messages-box-components ul {
    display: flex;
    width: 95%;
    background-color: rgb(255, 255, 255);
    padding: 0;
    margin: 0;
    height: 170px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    #container-loading{
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

#container-message-box #container-ul-messages-box-components ul #container-message-sender{
    display: flex;
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding: 0px 0px 0px 10px;
    justify-content: right;
    box-sizing: border-box;
}

#container-message-box #container-ul-messages-box-components ul #container-message-recipient{
    display: flex;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0 10px 0px 0px;
    margin: 0;
    justify-content: left;
    box-sizing: border-box;
}

#container-message-box #container-ul-messages-box-components ul div li{
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0;
    margin: 0;
}

#container-message-box #container-ul-messages-box-components ul div #li-sent{
    width: 85%;
    background-color: rgb(177, 80, 80);
    margin: 2.5px;
    border-radius: 4px;
    padding: 5px;

    p {
        padding: 0;
        margin: 0;
        color: rgb(255, 255, 255);
        font-size: 10px;

        @media (max-width: 850px){
            font-size: 8px;
        }

        @media (min-width: 900px){
            font-size: 1vw;
        }

        @media (min-width: 1200px){
            font-size: 1vw;
        }
    }

    #container-date-hour {
        display: flex;
        justify-content: right;
        font-weight: bold;

        span{
            color: white;
            font-size: 8px;
            margin-top: 5px;
        }
    }
}

#container-message-box #container-ul-messages-box-components ul div #li-did-not-send{
    width: 85%;
    background-color: rgb(173, 139, 139);
    margin: 5px;
    border-radius: 4px;
    padding: 5px;

    p {
        padding: 0;
        margin: 0;
        color: rgb(255, 255, 255);

        @media (min-width: 850px){
            font-size: 1vw;
        }

        @media (min-width: 900px){
            font-size: 1vw;
        }

        @media (min-width: 1200px){
            font-size: 1vw;
        }
    }

    #container-date-hour {
        display: flex;
        justify-content: right;
        font-weight: bold;

        span{
            color: white;
            font-size: 8px;
            margin-top: 5px;
        }
    }
}

#container-message-box #container-ul-messages-box-components #container-field-button {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 0;
    width: 100%;
    height: 50px;
    background-color: rgb(60, 60, 60);
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

#container-message-box #container-ul-messages-box-components #container-field-button textarea{
    padding: 5px 5px;
    margin: 0;
    width: 80%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border-style: none;
    border: 1px solid #ccc;
    transition: border-color 0.3s, box-shadow 0.3s;
    outline: none;
    background-color: #fff;
    resize: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-message-box #container-ul-messages-box-components #container-field-button textarea:focus {
    border-color: #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#container-message-box #container-ul-messages-box-components #container-field-button button{
    background-color: transparent;
    margin-right: 5px;
    border: none;
    cursor: pointer;
    width: fit-content;
    height: fit-content;

}

#container-message-box #container-ul-messages-box-components #container-field-button button img{
    width: 20px;
    height: 20px;

    @media (max-width: 700px){
        width: 15px;
        height: 15px;
    }
}