.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    height: 100vh; /* Define a altura da .error-container para 100% da altura da viewport */
    font-size: 24px; /* Tamanho da fonte desejado */
}

.page-not-found-error-message {
    color: red;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
}

.back-home-button {
    margin-top: 20px; /* Espaço superior para separar o botão do texto */
    border-radius: 5px;
    background-color: rgb(156, 29, 29);
    color: white;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 10px;
    border: 0.5px solid white;
    cursor: pointer;
}

.back-home-button:hover {
    background-color: darkred;
    box-shadow: none;
}
