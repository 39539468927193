#header-app-tool-bar {
  background-color: rgb(165, 42, 42);
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
}

#menu-container{
  display: flex;
  align-items: center;

  #container-messages-notifications{
    display: flex;
    align-items: center;
  }
}

#container-notifications-component{
  margin-right: 10px;
}

#toolbar {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 500px) {
  #toolbar {
    justify-content: left;
  }
}

@media (min-width: 500px) {
  #toolbar {
    justify-content: space-between;
  }
}

#logo-search-input {
  display: flex;
  align-items: center;
}

#container-logo {
  cursor: pointer;
  user-select: none;
}

#container-logo img {
  height: 50px;
}

@media(max-width: 500px) {
  #container-logo img {
    height: 30px;
  }
}

#logo-seach-input {
  display: flex;
  align-items: center;
}

#logo-seach-input input {
  padding: 5px 10px 5px 35px;
  width: 100%;
}

#button-search{
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: 5px;
}

#button-search:hover{
  background-color: rgb(139,0,0);
}

#button-search img{
  padding: 5px;
}

#menu-button {
  background-color: rgb(156, 29, 29);
  border: none;
  border-radius: 5px;
  height: fit-content;
  width: fit-content;
  padding: 5px;
  margin-right: 10px;
  justify-content: center;
  cursor: pointer;
}

#menu-button img {
  height: 30px;
}

@media (max-width:500px) {
  #menu-button img {
    height: 20px;
  }
}

.sign-in-button {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(156, 29, 29);
  color: white;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 10px;
  border: 2px solid white;
  cursor: pointer;
  margin-right: 20px;
}

.sign-in-button:hover {
  background-color: darkred;
  box-shadow: none;
}

.profile-button {
  display: flex;
  margin-right: 20px;
  align-items: center;
  border-radius: 50%;
  background-color: #9c1d1d;
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  user-select: none;
}

@media (min-width: 300px) {
  .profile-button {
    margin-right: 20px;
  }
}

.profile-button:hover {
  background-color: rgb(139,0,0);
  box-shadow: none;
}

.profile-image-app-bar {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.menu {
  border-radius: 2%;
  position: absolute;
  top: 60px;
  right: 20px;
  color: #4b4b4b;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
  margin-right: 20px;
  z-index: 1000;
}

.menu ul {
  width: 200px;
  list-style-type: none;
  padding: 0;
  padding-top: 0;
  margin: 0;
  color: #4b4b4b;
  text-align: center;
}

.menu li {
  padding: 10px;
  margin: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.menu li:hover {
  border-radius: 4%;
  background-color: rgb(216, 216, 216);
  box-shadow: none;
}

.menu a {
  text-decoration: none;
  color: black;
  font-family: sans-serif;
}

.logout-icon {
  width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.configure-icon {
  width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.sign-in-icon {
  color: white;
  align-items: center;
  width: 20px;
}
