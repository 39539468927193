.container-mix-project-contents{
  z-index: 2;
  position:fixed;
  margin-top: 200px;
  margin-left: 50px;
}

#container-useful-useless {
  display:flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-useful-contents {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 70%;
  width: 80vw;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  align-items: center;
  margin-bottom: 50px;
}

#container-top-manage-project {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
}


#container-title-line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 400px;

  /* @media (max-width:) */
  div{
    width: 100%;
  }

  #container-title{
    width: fit-content;
    margin: 0px 10px 0px 0px;

    #project-name {
      display: flex;
      flex-grow: 1;
      width: fit-content;
      margin: 0px;
      position: relative;
      text-justify: center;
      overflow-wrap: break-word; 
      word-break: break-word;
    }
  }
}




input[name="input-edit-title"] {
  font-size: 2em; 
  font-weight: bold; 
  outline: none; 
  padding: 0; 
  margin: 0; 
  background-color: transparent; 
}

#edit-project-title-button{
  background-color: rgba(0, 0, 0, 0.74);
  border-radius: 5px;
  border: none;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  background-position: center;
  background-size: cover;
  margin-left: 5px;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 0, 0);
  }

  img{
    height: 20px;
  }
}

#container-visualisations-privacity {
  display: flex;
  width: 100%;
  justify-content: end;
}

#container-number-label-project-visitors {
  display: flex;
  margin-right: 50px;
  align-items: center;
}

#container-button-project-privacity{
  display: flex;
  margin-left: 100px;
  /* align-content: center; */
  flex-direction: row;
}

#public-description-project-management{
  font-size: 15px;
}

#container-play-project{
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

#container-top-tracks{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

#container-master-track{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: left;
  width: 100%;
}

#container-project-player-master-mixed{
  display: flex;
  width: 100%;
}

#container-sketch-track{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  width: 100%;
}

#container-project-player-sketch-mixed{
  display: flex;
  width: 90%;
  height: 80px;
}

#button-commit-sketch:hover {
  background-color: #555;
}

#mix-contents-button {
  width: 70px;
  height: 70px;
  border: 4px solid black; 
  border-radius: 50%;
  cursor: pointer;
}

#container-select-all{
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
  margin-left: 100px;
  margin-top: 10px;
  margin-bottom: 10px; 

  @media (max-width: 400px) {
    width: 80%;
    margin-left: 30px;
  }
}

#container-project-duration {
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-left: auto;

  h2{
    font-size: 1.5vw;
    margin-right: 10px;

    @media(max-width: 900px){
      font-size: 2vw;
    }

    @media(max-width: 600px){
      font-size: 3vw;
    }
  }
}


#container-title-ul {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#container-title-useful-contents {
  display: flex;
  min-width: 35px;
  position: relative;
  height: fit-content;
  left: -5px;
}

#useful-contents-letters-title {
  border: 2px solid black;
  padding: 10px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 20px ;

  #letter-useful-contents {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#container-record-button-manage-project{
  position: fixed;
  top: 68vh;
  right: 5vw;
  z-index: 2;
}

#container-ul-useful-contents {
  display: flex;
  position: relative;
  margin: 0 auto; /* Mantenha as margens esquerda e direita centradas */
  margin-left: 10px;
  margin-right: 50px;
  border-radius: 5px;
  margin-bottom: 2px;
  white-space: nowrap;
  background-color: #fafafa;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 350px;
  min-width: 90%;
  max-width: 90%;
  background: linear-gradient(90deg, #e2e2e2 1px, transparent 1px);
  background-size: 5px 100%;
}

#li-manage-project {
  overflow: visible;
  display: flex;
  /* position: relative;s */
  padding-top: 0px;
  width: fit-content;
  height: 70px;
}

#container-project-content-instrument-name {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 50px;
  background-color: black;
  align-content: center;
  padding-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 5px;

  label {
    font-size: 12px;
    display: flex;
    margin: 0;
    position: relative;
    transform: rotate(-90deg);
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
    height: 0px;
    width: 0px;
    color: white;
    border-radius: 2px;
    padding: 5px 5px 2px 5px;
    user-select: none;
  }
}

#container-container-title-project-content-player{
  /* width: 95%; */
  margin-bottom: 5px;
  max-width: 90%;
  z-index: 2;
  width: fit-content;
  position: relative;
  left: -10px;

  #container-player-project-content-title{
    display: flex;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 2px;
    left: 30px;
    z-index: 1;
    width: fit-content;
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    padding: 2px 5px 2px 5px;
  }
}

#container-title-content-project-content{

  #project-content-title{
    display: flex;
    padding: none;
    margin: 0;
    display: flex;
    padding: none;
    margin: 0;
    user-select: none;
  }
}

#container-title-useless-contents {
  display: flex;
  min-width: 35px;
  position: relative;
  height: fit-content;
  left: -5px;
}

/* #useless-contents-letters-title {
  border: 2px solid black;
  padding: 10px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 20px ;

  #letter-useless-contents {
    display: flex;
    justify-content: center;
    align-items: center;
  }
} */

#container-ul-useless-contents {
  display: flex;
  position: relative;
  margin: 0 auto; 
  margin-left: 10px;
  margin-right: 50px;
  border-radius: 5px;
  margin-bottom: 2px;
  white-space: nowrap;
  background-color: #fafafa;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 350px;
  min-width: 90%;
  max-width: 90%;
  background: linear-gradient(90deg, #e2e2e2 1px, transparent 1px);
  background-size: 5px 100%;
  /* z-index: 3px; */
}

/* #project-content-title {
} */
/* .container-sketch-track{
  display: flex;
  position: relative;
  justify-content: left;
  width: 90%;
} */

#unordered-list-manage-project {
  list-style: none;
  padding: 0px 0px 0px 2px;
  margin: 2px 0px 2px 0px;
  height: 100%;
  width: fit-content;

  overflow-x: auto;
  
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    transition: background-color 0.2s, height 0.2s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    height: 8px;
  }
}

#button-commit-sketch{
  width: 70px;
  height: 70px;
  border: 4px solid black; 
  border-radius: 50%;
  cursor: pointer;
}


.mix-contents-button:hover {
  background-color: #555;
}


.useful-contents-title {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0px 5px 0px 5px;
}




.useless-contents-title {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0px 5px 0px 5px;
}



/* .container-title-useless-contents {
  display: flex;
  position: relative;
  height: fit-content;
  left: -5px;
} */




.container-ul-contents ul {
    margin-top: 0px;
}


/* .container-player-project-content-title {
  display: flex;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 2px;
  left: 30px;
  z-index: 1;
  width: fit-content;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 2px 5px 2px 5px;
} */




.unordered-list-usability-button{
  list-style: none;
  width: 100%;
  height: fit-content;
  padding: 0px 0px 0px 2px;
  margin: 2px 0px 2px 0px;
}

.container-player-projects-track-title{
  display: flex;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 40px;
  left: 15px;
  z-index: 3;
  width: fit-content;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 2px 5px 2px 5px;
}

.project-player-projects-track-title{
  display: flex;
  padding: none;
  margin: 0;
  user-select: none;
}


