.container-loading-spinner{
    height: 15vh; /* 100% da altura da viewport */
    width: 100vw; /* 100% da largura da viewport */
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-spinner{
    display: flex;
    position: absolute;
    height: 80px;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px){
        height: 50px;
    }
}

.loader-spinner .spinner-stroke:nth-child(1){
    display: block;
    position: relative;
    background: brown;
    height: 30%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-1 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(2){
    display: block;
    position: relative;
    background: brown;
    height: 50%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-2 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(3){
    display: block;
    position: relative;
    background: brown;
    height: 75%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-3 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(4){
    display: block;
    position: relative;
    background: brown;
    height: 40%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-4 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(5){
    display: block;
    position: relative;
    background: brown;
    height: 35%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-5 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(6){
    display: block;
    position: relative;
    background: brown;
    height: 80%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-6 0.8s linear infinite;
}

.loader-spinner .spinner-stroke:nth-child(7){
    display: block;
    position: relative;
    background: brown;
    height: 30%;
    width: 7px;
    border-radius: 50px;
    margin: 0 5px;
    animation: animate-7 0.8s linear infinite;
}

@keyframes animate-1 {
    50%{
        height: 80%;
    }
}

@keyframes animate-2 {
    50%{
        height: 20%;
    }
}

@keyframes animate-3 {
    50%{
        height: 100%;
    }
}

@keyframes animate-4 {
    50%{
        height: 90%;
    }
}

@keyframes animate-5 {
    50%{
        height: 20%;
    }
}

@keyframes animate-6 {
    50%{
        height: 20%;
    }
}

@keyframes animate-7 {
    50%{
        height: 50%;
    }
}

.spinner-stroke:nth-child(1){
    animation-delay: 0s;
}
.spinner-stroke:nth-child(2){
    animation-delay: 0s;
}
.spinner-stroke:nth-child(3){
    animation-delay: 0.6s;
}
.spinner-stroke:nth-child(4){
    animation-delay: 0.9s;
}
.spinner-stroke:nth-child(5){
    animation-delay: 0.6s;
}
.spinner-stroke:nth-child(6){
    animation-delay: 0.3s;
}
.spinner-stroke:nth-child(7){
    animation-delay: 0s;
}