#container-loading-modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;
    align-items: center;
    justify-content: center;

    img{
      height: 10vw;
      width: 10vw;
    }

    @media (max-width: 800px){
      img{
        height: 15vw;
        width: 15vw;
      }
    }
  }
  