.container-user-config-side-bar{
    display: flex;
    position: absolute;
    top: 90px;
    height: fit-content;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    user-select: none;
    border-radius: 0px 10px 10px 0px;
    width: 18vw;
    min-width: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.container-user-config-side-bar ul{
    flex-direction: column;
    margin: 0px;
    padding: 1%;
    width: 100%;
    list-style: none;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 5px 5px 0px;
}

.container-user-config-side-bar ul li{
    padding: 2px;
    margin: 2px;
    height: fit-content;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    color: rgb(85, 85, 85);
}

.container-user-config-side-bar ul li:hover{
    background-color: rgb(224, 224, 224);
    cursor: pointer;
}

.container-user-config-side-bar ul li.selected {
    background-color: rgb(224, 224, 224);
  }

.container-user-config-side-bar ul li label{
    font-size: 4vw;

    @media (min-width: 200px) {
        font-size: 2vw;
      }

    @media (min-width: 500px) {
        font-size: 2vw;
    }

    @media (min-width: 700px) {
        font-size: 1vw;
    }

    @media (min-width: 1000px) {
        font-size: 1vw;
    }
}

@media screen and (max-width: 600px) {
    label {
      font-size: 0.8em;
    }
  }