#container-content-info-edit-modal{
    display: flex;
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 100;
    height: 100%;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;


    
    

    #container-screen-transparent-content-info-modal{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    
        #container-content-info-modal {
            border-radius: 10px;
            width: 60%;
            height: fit-content;
            margin: 1em;
            padding: 0.5em 0.5em;
            background-color: #FFF;
            box-shadow: 0 0 20px rgba(0,0,0, 0.8);

            @media (max-width: 540px){
                width: 70%;
            }

            @media (max-width: 450px){
                width: 80%;
            }

            @media (min-width: 900px){
                width: 50%;
            }

            #container-top-content-informations {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: fit-content;
                margin-bottom: 10px;

                #container-title-date-project-content{
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 400px){
                        width: 200px;
                    }

                    @media (max-width: 380px){
                        width: 150px;
                    }

                    h1{
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        width: auto;
                        font-size: 18px;
                        margin: 0px;

                        @media (max-width: 600px){
                            font-size: 16px;
                        }
                    }

                    h6{
                        margin: 0px;
                        font-size: 10px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-weight: bold;
                        color: rgb(102, 102, 102);
                        margin-top: 2px;
                    }

                    #container-title-input-content-modal{
                        display: flex;
                        flex-direction: column;

                        input {
                            border: 1px solid #ccc;
                            border-radius: 8px;
                            padding: 5px 5px;
                            font-size: 16px;
                            transition: border-color 0.3s, box-shadow 0.3s;
                            outline: none; 
                            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                            background-color: #fff;
                            color: #000;  

                            &:focus {
                                border-color: #000;  /* Borda preta no foco */
                                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* Sombra mais pronunciada no foco */
                            }
                            
                            &::placeholder {
                                color: #888;  /* Cor do placeholder cinza */
                                opacity: 1;  /* Garantir que a cor se aplique em todos os navegadores */
                            }
                            
                            &:hover {
                                border-color: #000;  /* Mudar a cor da borda ao passar o mouse */
                            }

                            @media (max-width: 650px){
                                width: 200px;
                                font-size: 14px;
                            }

                            @media (max-width: 610px){
                                width: 180px;
                                font-size: 12px;
                            }

                            @media (max-width: 570px){
                                width: 160px;
                            }
                        }
                    }
                }
                
                #continer-download-abled-button-close{
                    display: flex;
                    flex-direction: row;
                    align-items: start;

                    #container-download-abled-icon{
                        margin-right: 20px;
                        align-items: center;
                        display: flex;
                        margin-top: 0.5em;

                        div{
                            display: flex;
                            align-items: center;
                            
                            label{
                                margin-right: 5px;
                                font-size: 12px;
    
                                @media (max-width: 500px){
                                    font-size: 10px;
                                }
    
                                @media (max-width: 650px){
                                    font-size: 8px;
                                }
                            }
                        }

                        #container-download-abled-content-info-modal{
                            text-wrap: nowrap;
                        }
                    }

                    button{
                        align-items: center;
                        background-color: #ca2500;
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        height: fit-content;
                        padding: 5px;
                        transition: background-color 0.5s ease;
    
                        &:hover {
                            background-color: #a01d00;
                            cursor: pointer;
                        }
                    }
                }
            }

            #container-middle-1-content-information{

                #instrument-content-info{
                    display: flex;
                    flex-direction: row;
                    /* align-items: end; */
                    /* margin-top: 15px; */
                    align-items: center;
                    /* align-content: center; */
                    margin: 0px 0px 5px 0px;
    
                    label {
                        font-size: 12px;
                        margin-right: 5px;
                        margin-bottom: 0px;
                        font-weight: bold;
                    }
    
                    h6{
                        margin: 0px;
                        font-weight: lighter;
                    }

                    select{
                        background-color: brown;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-weight: bold;
                        color: white;
                        border: none;
                        border-radius: 5px;
                        height: 25px;
                        width: 150px;
                        margin-right: 5px;
                        margin-bottom: 0px;
                        text-align: center;
                        font-size: 12px;
                      }
                }

                #duration-content-info{
                    display: flex;
                    flex-direction: row;
                    align-items: end;
                    /* margin-top: 5; */
                    margin-bottom: 5px;
    
                    label {
                        font-size: 12px;
                        margin-right: 5px;
                        margin-bottom: 0px;
                        font-weight: bold;

                    }
    
                    h6{
                        margin: 0px;
                        font-weight: lighter;
                    }
                }
            }

            #container-middle-2-content-information{

                pre{
                    font-size: 14px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    text-wrap: wrap;
                    margin: 10px 0px 10px 0px;
                }

                #container-label-input-description{
                    display: flex;
                    flex-direction: column;

                    /* label{

                    } */

                    textarea{
                        border: 1px solid #ccc;
                        border-radius: 8px;
                        padding: 5px 5px;
                        font-size: 16px;
                        transition: border-color 0.3s, box-shadow 0.3s;
                        outline: none; 
                        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                        background-color: #fff;
                        color: #000;
                        resize: none;

                        &:focus {
                            border-color: #000;  /* Borda preta no foco */
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* Sombra mais pronunciada no foco */
                        }
                        
                        &::placeholder {
                            color: #888;  /* Cor do placeholder cinza */
                            opacity: 1;  /* Garantir que a cor se aplique em todos os navegadores */
                        }
                        
                        &:hover {
                            border-color: #000;  /* Mudar a cor da borda ao passar o mouse */
                        }
                    }
                }

                #container-content-player{
                    display: flex;
                    margin: 5px 0px 10px 0px;
                }
            }

            #container-bottom-content-information{
                display: flex;
                width: 100%;
                justify-content: end;

                #container-buttons-content-information-modal{

                    height: 40px;
                    width: fit-content;

                    display: flex;
                    align-items: center;
                    margin-right: 5px;

                    #container-button-edit-cancel{
                        display: flex;
                        margin-right: 10px;

                        div{
                            display: flex;

                            button {
                                color: white;
                                background-color: rgb(70, 70, 70);
                                border-radius: 5px;
                                border: none;
                                margin-right: 5px;
                                height: 30px;
                                width: 60px;
                                align-items: center;
                                align-content: center;
                                cursor: pointer;
                                transition: background-color 0.3s, box-shadow 0.3s;
                                
                                &:hover {
                                    background-color: rgb(0, 0, 0);
                                    box-shadow: none;
                                }
                            }

                        }

                    }
                    #container-button-delete{
                        button{
                            align-items: center;
                            background-color: #ca2500;
                            border: none;
                            border-radius: 5px;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            height: 30px;
                            width: 60px;
                            transition: background-color 0.5s ease;
                            
                            &:hover {
                                background-color: #a01d00;
                                cursor: pointer;
                            }
                            
                            img{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                    

                }
            }
        }
    }
}