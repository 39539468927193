#container-comment{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    border: 1px solid rgb(189, 189, 189);
    margin-top: 5px;
    margin-bottom: 5px;
}

#container-comment-image-nickname-owner{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0px 0px 5px;
    /* border-radius: 100%; */

    #container-comment-profile-image{
        width: fit-content;
        border-radius: 50%;
        /* overflow: hidden; */
        
        img{
            height: 35px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    h4{
        margin: 0px 0px 0px 5px;
    }
}

#container-comment-middle{

    pre{
        margin: 5px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-break: break-all;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
}

#container-ul-comments{
    list-style: none;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    margin: 0px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
    }
}

#container-comment-top{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

#container-comment-created-at{

    h6{
        color: rgb(122, 122, 122);
        margin: 0px;
        margin-left: 10px;
    }
}

#container-button-delete-comment{
    margin-top: 10px;
    margin-right: 5px;

    button{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 5px 5px 5px 5px;
        color: #ddd;
        background-color: rgb(220, 0, 0);
        border-radius: 5px;
        border: none;
        opacity: 0.8;
        cursor: pointer;
        /* width: 80px; */
        height: 25px;
        width: 25px;
        margin-right: 5px;
        font-weight: bold;


        @media (min-width: 400px) {
            font-size: 10px;
            white-space: nowrap;
        }

        @media (min-width: 300px) {
            /* width: 80px; */
            bottom: 10px;
            font-size: 10px;
            white-space: wrap;
            justify-content: center;
        }
    }

    button:hover{
        color: #ddd;
        background-color: rgb(175, 4, 4);
        border-radius: 5px;
        border: none;
        opacity: 1;
        transition: background-color 0.5s ease;
    }

    img{
        height: 15px;
    }
}

#container-loading-spinner{
    display: flex;
    width: 100%;
    justify-content: center;
}

#container-comment-bottom{
    height: 10px;
}
