#container-side-bar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

#side-bar-container {
    position: relative;
    z-index: 999;
    position: fixed;
    top: 80px;
    width: 200px;
    height: fit-content;
    background-color: #fafafa;
    color: rgb(85, 85, 85);
    padding: 0;
    margin: 0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    transition: 0.1s;
    padding: 5px;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 0 10px 10px 0px;
    font-weight: bold;
    user-select: none;
}

#side-bar-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
#side-bar-container li {
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
#side-bar-container li:hover {
    background-color: rgb(224, 224, 224);
    border-radius: 5px;
}
  