#container-page-member-groups{
  display: flex;
  height: 140vh;
  width: 100vw;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  @media (max-width: 300px) {
      width: 300px;
  }

    @media (max-width: 300px) {
        width: 300px;
    }


  #container-member-groups-left {
    background-color: rgb(245, 245, 245);
    flex: 0 0 10%;
  }

  #container-member-groups-center {
    display: flex;
    background-color: rgb(245, 245, 245);
    flex: 0 0 80%;
    flex-direction: column;
    height: 140vh;
    align-items: center;


    #container-form-and-member-groups {
      flex-direction: column;
      margin: 90px 0px 20px 0px;
      justify-content: center;
      align-items: center;
      top:0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 100vh;

      #container-member-groups {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        #container-ul-member-groups {
          display: flex;
          flex-direction: column;
          position: relative;
          padding: 2px;
          margin: 0px;
          border-radius: 5px;
          white-space: nowrap;
          overflow-y: auto;
          background-color: #fafafa;
          border: 1px solid #ddd;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          width: 100%;
          height: 596px;

          scrollbar-width: thin;
          scrollbar-color: #888 transparent;

          &::-webkit-scrollbar {
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 6px;
            transition: background-color 0.2s, height 0.2s;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
            height: 8px;
          }


          #ul-member-groups {
            display: flex;
            flex-direction: column;
            margin: 0px 5px 0px 0px;
            padding: 0px;

            #container-li-member-groups {
              display: flex;
              align-items: center;
              border-radius: 5px;
              width: 100%;
              height: fit-content;
              margin: 2px;
              border: 1px solid #afafaf;
              background-color: #f5f5f5;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              transition: background-color 0.1s;


              #li-member-groups {
                display: flex;
                flex-direction: column;
                width: 100%;
                cursor: pointer;

                #container-li-top {
                  display: flex;
                  width: calc(100% - 20px);
                  height: 50px;
                  margin-left: 10px;
                  margin-right: 10px;
                  align-items: center;
                  justify-content: space-between;
                  
  
                  #container-top-left {
                    display: flex;
                    flex-direction: column;
                                  
                    #container-group-title {
                      text-overflow: ellipsis;
                      width: 300px;
  
                      @media (max-width: 700px) {
                        width: 200px;
                        font-size: 12px;
                      }
  
                      @media (max-width: 600px) {
                        width: 150px;
                        font-size: 12px;
                      }
  
                      @media (max-width: 500px) {
                        width: 100px;
                        font-size: 12px;
                      }

                      h4 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 10px 0px 0px 0px;
                        height: fit-content;
    
    
                        @media (max-width: 500px) {
                          font-size: 12px;
                        }
                      }
                    }
  
                    #container-group-info {
                      font-size: 12px;
                      font-weight: bold;
                      margin: 5px 0px 10px 10px;
                    
                      @media (max-width: 500px) {
                        font-size: 8px;
                      }
  
                      #members-span {
                        margin-right: 10px;
                      }
                    }
                  }
  
  
                  #container-owner {
                    display: flex;
                    height: 30px;
                    align-items: center;
                    font-weight: bold;
                    margin-right: 5px;
  
                    @media (max-width: 500px) {
                      font-size: 10px;
                    }
  
                    #img-avatar {
                      height: 24px;
                      width: 24px;
                      margin-right: 5px;
                      border-radius: 100%;
                      object-fit:contain;
                    }
                  }
  
                  #button-more-info-group {
                    display: flex;
                    background-color: rgb(70, 70, 70);
                    border-radius: 5px;
                    border: none;
                    transition: background-color 0.3s, box-shadow 0.3s;
                    cursor: pointer;
                    height: 30px;
                    width: 40px;
                    justify-content: center;
                    align-items: center;
                  
                    @media (max-width: 500px) {
                      height: 20px;
                      width: 25px;
                    }
                  
                  
                    img {
                      height: 20px;
                      width: 20px;
                  
                      @media (max-width: 500px) {
                        height: 10px;
                        width: 10px;
                      }
                    }
                  }
                  
                  #button-more-info-group:hover{
                    background-color: rgb(0, 0, 0);
                    box-shadow: none;
                  }
                }
              }
            }
            
            #container-li-member-groups:hover {
                background-color: #cecece;
            }

            #container-li-middle{
              display: flex;
              background-color: rgb(255, 255, 255);
              margin: 10px;
              width: calc(100% - 35px);
              border-radius: 5px;
              border: 1px solid #afafaf;
              padding: 5px;
              margin: 0px 10px 10px 10px;
            
              pre {
                margin: 0;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                width: 100%;
                max-height: calc(1em * 4);
                overflow-y: scroll;
                cursor:default;
                min-height: 50px;
            
                scrollbar-width: thin;
                scrollbar-color: #888 #f5f5f5;
              
                &::-webkit-scrollbar {
                  width: 6px; 
                }
              
                &::-webkit-scrollbar-thumb {
                  background-color: #888;
                  border-radius: 6px;
                  transition: background-color 0.2s, height 0.2s;
                }
              
                &::-webkit-scrollbar-thumb:hover {
                  background-color: #555;
                  height: 8px;
                }
            
                &::-webkit-scrollbar-track {
                  background-color: #f5f5f5;
                  border-radius: 6px;
                }
            
                @media (max-width: 500px) {
                  font-size: 12px;
                }
              }
            }


            #container-li-bottom{
              
              ul{
                display: flex;
                align-content: center;
                list-style: none;
                flex-wrap: wrap;
                margin-top: 0px;
                margin-bottom: 10px;
                padding-left: 10px;
              }

              li{
                display: flex;
                width: fit-content;
                height: fit-content;
                padding: 2px;
                background-color:  rgb(0, 190, 0);
                color: rgb(255, 255, 255);
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                border: solid 1px rgb(0, 190, 0);
                margin: 2px;
                border-radius: 5px;
                white-space: nowrap;
                user-select: none;

                h6{
                    margin: 0px;
                }
            }
            } 
          }
        }
      }

    }

  }

  #container-member-groups-right {
    background-color: rgb(245, 245, 245);
    flex: 0 0 10%;
  }

}

#no-groups-yet {
  text-align: center;
  color: rgb(85, 85, 85);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
}

.groups-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 70vw;
  height: 600px;
  border-radius: 0px 5px 5px 5px;
}




















#container-loading-spineer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
}



#li-ellipsis label{
  margin: 0px 5px 0px 5px;
  user-select: none;
}