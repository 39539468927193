#modal-group-select-genre {
    position: fixed;
    /* display: flex; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    z-index: 1000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#modal-group-genres-container {
        border-radius: 20px;
        position: fixed;
        max-width: 60vw;
        top: 10%;
        left: 0;
        right: 0;
        height: 80%;
        margin: 0 auto;
        padding: 1.5em 1.5em;
        background-color: #FFF;
        box-shadow: 0 0 20px rgba(0,0,0, 0.8);


    @media (min-width: 700px) {
        width: 40vw;
    }
}

#close-group-genre-selection {
    position: absolute;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 5px;
    top: -40px;
    right: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #ca2500;
    transition: background-color 0.5s ease
}

#close-group-genre-selection:hover {
    background-color: #a01d00;
    cursor: pointer;
}

#container-button-create-container {
    display: flex;
    height: fit-content;
}


#container-music-genres-list {
    height: 90%;
    background-color: #fafafa;
    border: 1px solid #ddd;
    overflow-y: auto;
    padding-left: 10px;
    margin-top: 5px;

    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
      }
}

#ul-musical-genre {
    list-style: none;
    padding: 0px;
}

#li-musical-genre label{
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}
#li-musical-genre label input[type="checkbox"] {
    display: none; /* Oculta o input padrão */
}

#li-musical-genre label .custom-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Define o formato de círculo */
    border: 2px solid rgba(255, 0, 0, 1); /* Cor da borda */
    margin-right: 10px; /* Espaçamento entre o checkbox e o texto */
    cursor: pointer; /* Adiciona um cursor ao elemento */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; /* Garante que o tamanho do elemento inclua a borda */
    margin-left: 5px;
}

#li-musical-genre label .custom-checkbox::before {
    content: '';
    position: absolute;
    top: 50%; /* Ajuste para posicionar o círculo interno no meio verticalmente */
    left: 50%; /* Ajuste para posicionar o círculo interno no meio horizontalmente */
    transform: translate(-50%, -50%); /* Move o círculo interno de volta para centrado */
    width: 10px; /* Tamanho do círculo interno */
    height: 10px; /* Tamanho do círculo interno */
    border-radius: 50%; /* Define o formato de círculo */
    background-color: transparent; /* Cor de fundo */
}

#li-musical-genre label input[type="checkbox"]:checked + .custom-checkbox::before {
    background-color: rgba(255, 0, 0, 1); /* Cor de fundo quando estiver marcado */
    background: radial-gradient(circle, rgba(255, 0, 0, 1), rgba(255, 255, 255, 0.8)); 
}

/* #unordered-list-projects-modal {
    list-style: none;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    margin: 0px;
    width: 100%;
    min-height: 20%;
    max-height: 20%;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
    }
} */

#container-title-modal-create-group h2{
    margin: 0px;
}

#no-master-files-yet{
    text-align: center;
    color: rgb(85, 85, 85);
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

#h5-musical-genre-description{
    margin: 0;
}