#mix-contents-button {
  display: flex;
  position: fixed;
  bottom: 120px; /* Ajuste conforme necessário */
  left: 20px; /* Ajuste conforme necessário */
  border: 4px solid black; 
  border-radius: 50%;
  background-color: #ffffff; /* Cor sem aspas */
  cursor: pointer;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ajuste conforme necessário, maior que outros elementos */
}
 
#mix-contents-button img{
  width: 20px;
  height: 20px;
}

#mix-contents-button:hover {
    background-color: #555;
}