#container-edit-user-configurations-informations{
    display: flex;
    position: absolute;
    background-color: rgb(245, 245, 245);
    height: 1200px;
    width: 100%;
    z-index: 1;
    top: 30px;
}

#container-left-edit-configurations{
    display: flex;
    height: 100%;
    width: 20%;
    background-color: rgb(245, 245, 245);
}

#container-center-edit-configurations{
    display: flex;
    justify-content: center;
    align-items: top;
    padding-top: 85px;
    background-color: rgb(245, 245, 245);
}

#container-form-general-config{
    display: flex;
    width: 50%;
}