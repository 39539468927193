.button-select-all-useful-contents{
  background-color: rgba(0, 0, 0, 0.60);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  justify-content: center;
  background-position: center;
  background-size: cover;
  margin-left: 5px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-select-all-useful-contents:hover {
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  border: none;
  justify-content: center;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}

.checkbox-container {
    position: relative;
    display: inline-block;
    width: 20px; /* Ajuste conforme necessário */
    height: 20px; /* Ajuste conforme necessário */
    cursor: pointer;
    
  }
  
  .checkbox-button-project {
    opacity: 0;
    position: absolute;
  }
  
.checkmark {
    position: absolute;
    top: 2px; /* Espaço entre a borda e a bolinha vermelha */
    left: 2px; /* Espaço entre a borda e a bolinha vermelha */
    width: 13px; /* Tamanho da bolinha vermelha */
    height: 13px; /* Tamanho da bolinha vermelha */
    border-radius: 50%;
    background-color: #fff;

    box-shadow:
    0 0 0 2px #f00;
}

.checkbox-container.selected .checkmark {
  position: absolute;
  width: 10px; /* Tamanho da bolinha vermelha */
  height: 10px; /* Tamanho da bolinha vermelha */
  border: 2px solid #fff;
  background: radial-gradient(circle, rgba(255, 0, 0, 1), rgba(255, 255, 255, 0.8)); /* Vermelho */

  box-shadow:
  0 0 0 2px #f00, /* Sombra para simular a borda interior (cor vermelha, 5px de largura) */
}
  