#container-modal-group-details {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    z-index: 1000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-group-details{
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    position: fixed;
    max-width: 60vw;
    top: 10%;
    left: 0;
    right: 0;
    height: fit-content;
    margin: 0 auto;
    padding: 1.5em 1.5em;
    background-color: #FFF;


    @media (min-width: 700px) {
        width: 40vw;
    }
}

#close-group-details-modal {
    position: absolute;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 5px;
    top: -40px;
    right: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #ca2500;
    transition: background-color 0.5s ease
}

#close-group-details-modal:hover {
    background-color: #a01d00;
    cursor: pointer;
}

#container-top-group-details-modal{
    display: flex;
    flex-direction: row;
}

#container-title-group-details{
    width: 70%;
}

#container-title-group-details h2 {
    flex: 1;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    width: 100%;
    font-size: 4vw;
    word-wrap: break-word;

    @media (min-width: 600px) {
        font-size: 20px;
    }
}

#container-top-group-details-modal i {
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
    padding-top: 0;
    border-radius: 10px;
    height: fit-content;
    border: 1px solid #888;
    text-align: justify;
}

#container-group-descriptions {
    height: 20vw;
    background-color: #fafafa;
    border: 1px solid #ddd;
    overflow-x: auto;
    padding: 10px 5px 10px 5px;
    margin-top: 10px;

    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;

    @media (min-width: 400px) {
        height: 15vw;
    }

    @media (min-width: 500px) {
        height: 20vw;
    }

    @media (min-width: 500px) {
        height: 15vw;
    }

    @media (min-width: 700px) {
        height: 10vw;
    }
  
    &::-webkit-scrollbar {
      width: 6px; 
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
      transition: background-color 0.2s, height 0.2s;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      border-radius: 6px;
    }
}

#container-group-descriptions pre {
    margin: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 100%;
    max-height: 100%;
    /* overflow-y: scroll; */
    cursor:default;
    min-height: 50px;
    font-size: 10px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
}

#container-dates-nmembers-delete-enter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

#row-nmember-group-details-modal{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#row-nmember-group-details-modal h5{
    margin: 0px;
}

#container-date-column-group-details {
    display: flex;
    flex-direction: column;
}

#container-date-row-group-details-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

#container-date-row-group-details-modal label{
    font-size: 2vw;
    white-space: nowrap;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}

#container-date-row-group-details-modal h5{
    font-size: 2vw;
    white-space: nowrap;
    margin: 0px 0px 0px 5px;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}

#container-nmembers-group-details-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    width: fit-content;
    justify-content: center;
}

#container-row-nmembers-group-details-modal {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

#container-row-nmembers-group-details-modal h5{
    font-size: 2vw;
    white-space: nowrap;
    margin: 0px 0px 0px 0px;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}

#container-row-nmembers-group-details-modal label{
    font-size: 2vw;
    white-space: nowrap;
    width: fit-content;
    margin: 0px 5px 0px 0px;

    @media (min-width: 300px) {
        font-size: 8px;
    }
}

#container-right-dates-nmembers-delete-enter{
    display: flex;
    flex-direction: row;
    width: fit-content;

    @media (max-width: 500px) {
        flex-direction: column;
    }
}

#button-delete-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px 5px 5px 5px;
    color: #ddd;
    background-color: rgb(220, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    width: 80px;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    @media (min-width: 400px) {
        width: 80px;
        bottom: 10px;
        font-size: 10px;
        white-space: nowrap;
    }

    @media (min-width: 300px) {
        width: 80px;
        bottom: 10px;
        font-size: 10px;
        white-space: wrap;
        justify-content: center;
    }
}

#button-delete-group:hover{
    color: #ddd;
    background-color: rgb(175, 4, 4);
    border-radius: 5px;
    border: none;
    opacity: 1;
    transition: background-color 0.5s ease;
}

#button-delete-group img{
    height: 20px;
}

#button-enter-group{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #ddd;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    width: 80px;
    bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    @media (min-width: 400px) {
        width: 80px;
        bottom: 10px;
        font-size: 10px;
        white-space: nowrap;
    }

    @media (min-width: 300px) {
        width: 80px;
        bottom: 10px;
        font-size: 10px;
        white-space: wrap;
        flex-direction: column;
        justify-content: center;
    }
}

#button-enter-group:hover{
    color: #ddd;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    border: none;
    opacity: 1;
    transition: background-color 0.5s ease;
}

#ul-musical-genres-group-details-modal {
    display: flex;
    flex-wrap: wrap;
    list-style: none;   
    border-radius: 10px;
    padding: 2px;
    margin: 0px;
    width: 100%;
    min-height: 20%;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

#ul-musical-genres-group-details-modal::-webkit-scrollbar {
    height: 6px;
}

#ul-musical-genres-group-details-modal::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    transition: background-color 0.2s, height 0.2s;
}

#ul-musical-genres-group-details-modal::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    height: 8px;
}

#ul-musical-genres-group-details-modal li {
    width: fit-content; /* 33.33% para dividir em três colunas, subtraindo o espaçamento */
    margin-bottom: 10px; /* Espaçamento abaixo dos itens */
}


#li-musical-genres-group-details {
    width: fit-content;
}

#li-musical-genres-group-details h5{
    margin: 0px;
    width: fit-content;
}

#li-musical-genres-group-details{
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 2px;
    color: rgb(0, 190, 0);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: solid 1px;
    margin: 2px;
    border-radius: 5px;
    white-space: nowrap;
    user-select: none;
}