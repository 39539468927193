#container-groups-search{

    #container-nothing-to-show-groups-search{
      display: flex;
      position: relative;
      top: 50px;
      justify-content: center;

      h2{
          color: rgb(129, 129, 129);
          width: fit-content;

          @media (max-width: 800px) {
              font-size: 18px;
          }

          @media (max-width: 600px) {
              font-size: 12px;
          }
      }
    }

    ul{
        padding: 0px;

        #container-li-search-group {
            border: 1px solid #afafaf;
            background-color: #f5f5f5;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            transition: background-color 0.1s;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 2px;
            &:hover {
                background-color: #cecece;
            }
        
            #li-group-search {
                display: flex;
                flex-direction: column;
                width: 100%;
                cursor: pointer;
                padding: 5px;
                box-sizing: border-box;
        
                #container-li-top-group-search{
                    display: flex;
                    width: calc(100% - 20px);
                    height: 50px;
                    margin-left: 10px;
                    margin-right: 10px;
                    align-items: center;
                    justify-content: space-between;
        
                    #container-li-top-left-group-search{
                        display: flex;
                        flex-direction: column;
                                      
                        #container-group-title {
                          text-overflow: ellipsis;
                          width: 300px;
        
                          @media (max-width: 700px) {
                            width: 200px;
                            font-size: 12px;
                          }
        
                          @media (max-width: 600px) {
                            width: 150px;
                            font-size: 12px;
                          }
        
                          @media (max-width: 500px) {
                            width: 100px;
                            font-size: 12px;
                          }
        
                          h4 {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin: 10px 0px 0px 0px;
                            height: fit-content;
        
        
                            @media (max-width: 500px) {
                              font-size: 12px;
                            }
                          }
                        }
        
                        #container-group-info {
                          font-size: 12px;
                          font-weight: bold;
                          margin: 5px 0px 10px 10px;
                        
                          @media (max-width: 500px) {
                            font-size: 8px;
                          }
        
                          #members-span {
                            margin-right: 10px;
                          }
                        }
                    }
        
                    #container-owner-group-search {
                        display: flex;
                        height: 30px;
                        align-items: center;
                        font-weight: bold;
                        margin-right: 5px;
        
                        @media (max-width: 500px) {
                          font-size: 10px;
                        }
        
                        #img-avatar {
                          height: 24px;
                          width: 24px;
                          margin-right: 5px;
                          border-radius: 100%;
                          object-fit:contain;
                        }
                      }
        
                      #button-more-info-group {
                        display: flex;
                        background-color: rgb(70, 70, 70);
                        border-radius: 5px;
                        border: none;
                        transition: background-color 0.3s, box-shadow 0.3s;
                        cursor: pointer;
                        height: 30px;
                        width: 40px;
                        justify-content: center;
                        align-items: center;
                      
                        @media (max-width: 500px) {
                          height: 20px;
                          width: 25px;
                        }
                      
                      
                        img {
                          height: 20px;
                          width: 20px;
                      
                          @media (max-width: 500px) {
                            height: 10px;
                            width: 10px;
                          }
                        }
                      }
        
                      #button-more-info-group:hover{
                        background-color: rgb(0, 0, 0);
                        box-shadow: none;
                      }
                }
        
                #container-li-middle{
                    display: flex;
                    background-color: rgb(255, 255, 255);
                    margin: 10px;
                    width: calc(100% - 35px);
                    border-radius: 5px;
                    border: 1px solid #afafaf;
                    padding: 5px;
                    margin: 0px 10px 10px 10px;
                  
                    pre {
                      margin: 0;
                      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                      width: 100%;
                      max-height: calc(1em * 4);
                      overflow-y: scroll;
                      cursor:default;
                      min-height: 50px;
                      white-space: pre-wrap;
                      font-size: 12px;
                  
                      scrollbar-width: thin;
                      scrollbar-color: #888 #f5f5f5;
                    
                      &::-webkit-scrollbar {
                        width: 6px; 
                      }
                    
                      &::-webkit-scrollbar-thumb {
                        background-color: #888;
                        border-radius: 6px;
                        transition: background-color 0.2s, height 0.2s;
                      }
                    
                      &::-webkit-scrollbar-thumb:hover {
                        background-color: #555;
                        height: 8px;
                      }
                  
                      &::-webkit-scrollbar-track {
                        background-color: #f5f5f5;
                        border-radius: 6px;
                      }
                  
                      @media (max-width: 500px) {
                        font-size: 12px;
                      }
                    }
                  }
                #container-li-bottom{
                    #container-genres-group-search{
                        
                        h4{
                            font-size: 12px;
                            margin: 5px 0pc 5px 0px;
        
                            @media (max-width: 500px) {
                                font-size: 10px;
                                
                            }
                        }
        
                        ul{
                            display: flex;
                            align-items: center;
                            list-style: none;
                            flex-wrap: wrap;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-left: 0px;
        
                            li{
                                display: flex;
                                width: fit-content;
                                height: fit-content;
                                padding: 2px;
                                background-color:  rgb(0, 190, 0);
                                color: rgb(255, 255, 255);
                                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                                border: solid 1px rgb(0, 190, 0);
                                margin: 2px;
                                border-radius: 5px;
                                white-space: nowrap;
                                user-select: none;
        
                                h6{
                                    margin: 0px;
                                }
                            }
                        }
        
                        @media (max-width: 500px) {
                            font-size: 12px;
                            
                        }
                    }
        
                }
            }
        }

        #container-li-search-project {
          flex-direction: column;
          list-style: none;
          width: 100%;
          min-height: 120px;
          height: fit-content;
          border: 1px solid #afafaf;
          background-color: #f5f5f5;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          box-sizing: border-box;
          margin-bottom: 2px;

            #li-project-search {
              display: flex;
              flex-direction: column;
              list-style: none;
              width: 100%;
              height: 100%;

              #container-title-description-project-search {
                display: flex;
                flex-direction: column;
                width: 100%;

                #container-top-li-project-search{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  height: 30px;
                  width: 100%;
                  justify-content: space-between;

                  h3{
                    margin: 0;
                  }

                  #container-project-title{
                    margin-left: 5px;
                  
                    @media (max-width: 500px) {
                      font-size: 10px;
                    }
                  }

                  #container-privacity-project{ 
                    i{
                    display: flex;
                    font-size: 2vw;
                    align-items: center;
                    user-select: none;
                  
                    @media (min-width: 500px) {
                      font-size: 15px;
                    }
                  }}

                  #container-usability{
                    display: flex;
                    flex-direction: column;
                    justify-content:flex-start;
                    height: max-content;
                    user-select: none;
                  
                    #container-usability-label{
                      display: flex;
                      flex-direction: row;
                      height: fit-content;
                  
                      label{
                        margin-right: 5px;
                        font-size: 10px;
                      }
                  
                      h4{
                        height: fit-content;
                        width: fit-content;
                        margin: 0;
                      }
                    }
                  }

                  #project-actions{
                    height: fit-content;
                    position: relative;
                    /* margin: 20px 20px 20px 20px; */
                    top: 10px;
                    right: 10px;
                    z-index: 3;
                  
                    @media (max-width: 500px) {
                      top: 0px;
                      right: 5px;
                    }
                  }
                }
              }
            }


            /* li{
                background-color: ;
            } */
        }
    }
}