#container-page-projects {
  display: flex;
  height: fit-content;
  width: 100%;
}

@media (max-width: 300px) {
  #container-page-projects {
    width: 300px;
  }
}

#container-form-list-projects {
  margin-top: 30px;
  width: 90%;
  height: 80%;
}

#header-manage-projects {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

#container-projects-titles {
  border: 2px solid black;
  border-radius: 20px;
  padding: 0px 5px;
  width: fit-content;
  height: fit-content;
  user-select: none;
  margin-right: 5px;
}

@media (max-width: 400px) {
  #container-projects-titles {
    font-size: 10px;
  }
}

#header-manage-projects h2 {
  margin: 0;
}

#container-filters-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 700px) {
  #container-filters-top {
    flex-direction: row;
    justify-content: flex-end;
  }
}

#container-filters-top select {
  background-color: brown;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 140px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 400px) {
  #container-filters-top select {
    height: 20px;
    font-size: 10px;
    width: 90px;
  }
}

#container-form-projects {
  display: flex;
  flex-direction: column;
  margin: 90px 0px 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#container-projects-left {
  background-color: rgb(245, 245, 245);
  width: 10vw;
}

@media (max-width: 600px) {
  #container-projects-left {
    width: 5vw;
  }
}

#container-center-projects {
  display: flex;
  background-color: rgb(245, 245, 245);
  width: 80vw;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

@media (max-width: 600px) {
  #container-center-projects {
    width: 90vw;
  }
}

#container-projects-right {
  background-color: rgb(245, 245, 245);
  width: 10vw;
}

@media (max-width: 600px) {
  #container-projects-right {
    width: 5vw;
  }
}

#container-manage-projects {
  display: flex;
  justify-content: start;
  margin-top: 10px;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#projects-container {
  display: flex;
  justify-content: center;
  align-items: top;
  background-color: rgb(245, 245, 245);
  height: 100%;
}

#no-projects-container-style {
  display: flex;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(189, 189, 189);
  min-height: 100px;
  justify-content: center;
  align-items: center;
}

#container-no-projects {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 30px;
}

#no-projects-yet {
  text-align: center;
  color: rgb(85, 85, 85);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
}

@media (max-width: 400px) {
  #no-projects-yet {
    font-size: 14px;
  }
}

@media (max-width: 300px) {
  #no-projects-yet {
    font-size: 12px;
  }
}

#ul-list-projects {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  background-color: #fafafa;
  height: 90%;
  box-sizing: border-box;
}

#container-loading-spinner {
  padding-top: 1em;
  padding-bottom: 1em;
}

#container-audio-player-my-project {
  padding: none;
  margin: none;
}

#container-ul-projects {
  display: flex;
  flex-direction: column;
  padding: 2px;
  margin: 0;
  border-radius: 5px;
  white-space: nowrap;
  /* overflow-x: auto; */
  background-color: #fafafa;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 600px;
  scrollbar-width: auto;
  scrollbar-color: #888 transparent;
}

#container-ul-projects::-webkit-scrollbar {
  height: 6px;
}

#container-ul-projects::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  transition: background-color 0.2s, height 0.2s;
}

#container-ul-projects::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  height: 8px;
}

#submit-button-container {
  flex-direction: row;
  display: flex;
  overflow: auto;
}

#container-title-description {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#container-li-projects {
  flex-direction: column;
  list-style: none;
  width: 100%;
  min-height: 155px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid rgb(189, 189, 189);
  margin-bottom: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

#li-project {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  height: 100%;
}

#container-usability {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: max-content;
}

#container-usability-label {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

#container-usability-label label {
  margin-right: 5px;
  font-size: 10px;
}

@media (max-width: 400px) {
  #container-usability-label label {
    font-size: 8px;
  }
}

#container-usability-label h4 {
  height: fit-content;
  width: fit-content;
  margin: 0;
}

#container-project-top {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  width: 100%;
}

#container-project-top h3 {
  margin: 0;
}

#container-privacity-project {
  align-content: center;
}

@media (max-width: 400px) {
  #container-privacity-project i {
    font-size: 12px;
  }
}

#container-project-another-info {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

#container-project-title {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

@media (max-width: 500px) {
  #container-project-title {
    font-size: 10px;
  }
}

#container-project-title i {
  display: flex;
  font-size: 2vw;
  align-items: center;
}

@media (min-width: 500px) {
  #container-project-title i {
    font-size: 15px;
  }
}

#project-actions {
  height: fit-content;
  position: relative;
  right: 10px;
  z-index: 3;
}

@media (max-width: 500px) {
  #project-actions {
  }
}

#container-title-privacity {
  display: flex;
  flex-direction: row;
}

#container-title-privacity h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  width: 300px;
  margin: 0px;
  align-content: center;
}

@media (max-width: 500px) {
  #container-title-privacity h3 {
    max-width: 150px;
    width: 150px;
  }
}

#container-usability-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 400px) {
  #container-usability-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

#container-footer-project {
  display: flex;
  justify-content: start;
}

#container-footer-project h5 {
  margin-left: 10px;
}

#project-description {
  display: flex;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 400px) {
  #project-description {
    font-size: 0.6em;
  }
}

#button-more-details{

  display: flex;
  background-color: rgb(70, 70, 70);
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  height: 30px;
  width: 40px;
  justify-content: center;
  align-items: center;
  padding: 5px;

  @media (max-width: 500px) {
    height: 20px;
    width: 25px;
  }

  &:hover{
    background-color: rgb(0, 0, 0);
    box-shadow: none;
  }

  img {
    height: 20px;
    width: 20px;
  
    @media (max-width: 500px) {
      height: 10px;
      width: 10px;
    }
  }
}