#container-form-labels-create-content {
    display: flex;
    justify-content: center;
}

#content-type {
    margin: 5px 0px 5px 0px;
}

#content-type span{
    user-select: none;
}

#container-form-add-content {
    display: relative;
    border-radius: 8px;
    width: 70vw;
    padding: 10px;
    margin: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #f3f3f3;
    border: 1px solid #ddd;
}

@media (min-width: 200px) {
    #container-form-add-content {
        max-width: 500px;
    }
}

#container-form-add-content h3{
    user-select: none;
}

#container-title {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    width: 60vw;
}

@media (min-width: 200px) {
    #container-title {
        max-width: 400px;
    }
}

#container-title input {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    outline: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #000;
}

#container-title input:focus {
    border-color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#container-title input::placeholder {
    color: #888;
    opacity: 1;
}

#container-title input:hover {
    border-color: #000;
}

#container-description {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    width: 60vw;
}

@media (min-width: 200px) {
    #container-description {
        max-width: 400px;
    }
}

#container-description textarea {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    outline: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #000;
    resize: none;
}

#container-description textarea:focus {
    border-color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#container-description textarea::placeholder {
    color: #888;
    opacity: 1;
}

#container-description textarea:hover {
    border-color: #000;
}

#container-description label {
    margin-bottom: 5px;
}

#container-description textarea {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-instrument-selection {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
}

#download-abled span{
    user-select: none;
}

#container-instrument-selection select {
    background-color: brown;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: 200px;
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: center;
}

#upload-button {
    margin-top: 10px;
    display: flex;
    color: white;
    background-color: brown;
    border-radius: 5px;
    border: none;
    height: 30px;
    width: 70px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

#upload-button:hover {
    background-color: darkred;
    box-shadow: none;
}

#button-open-form {
    margin-top: 10px;
    color: white;
    background-color: rgb(70, 70, 70);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#button-open-form:hover {
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}
