#container-projects-search{

  #container-nothing-to-show-projects-search{
    display: flex;
    position: relative;
    top: 50px;
    justify-content: center;

    h2{
        color: rgb(129, 129, 129);
        width: fit-content;

        @media (max-width: 800px) {
            font-size: 18px;
        }

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
  }

    ul{
        padding: 0px;
        
        #container-li-search-project {
            flex-direction: column;
            list-style: none;
            width: 100%;
            min-height: 120px;
            height: fit-content;
            border: 1px solid #afafaf;
            background-color: #f5f5f5;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            box-sizing: border-box;
            margin-bottom: 2px;
        
              #li-project-search {
                display: flex;
                flex-direction: column;
                list-style: none;
                width: 100%;
                height: 100%;
        
                #container-title-description-project-search {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
        
                  #container-top-li-project-search{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    justify-content: space-between;
        
                    h3{
                      margin: 0;
                    }
        
                    #container-project-title{
                      margin-left: 5px;
                    
                      @media (max-width: 500px) {
                        font-size: 10px;
                      }
                    }
        
                    #container-privacity-project{
                      margin-right: 10px;
                      i{
                        display: flex;
                        font-size: 2vw;
                        align-items: center;
                        user-select: none;
                      
                        @media (min-width: 500px) {
                          font-size: 15px;
                        }
                      }
                    }
        
                    #container-usability{
                      display: flex;
                      flex-direction: column;
                      justify-content:flex-start;
                      height: max-content;
                      user-select: none;
                    
                      #container-usability-label{
                        display: flex;
                        flex-direction: row;
                        height: fit-content;
                    
                        label{
                          margin-right: 5px;
                          font-size: 10px;

                          @media (max-width: 500px){

                            font-size: 8px;
                          }
                        }
                    
                        h4{
                          height: fit-content;
                          width: fit-content;
                          margin: 0;
                          font-size: 10px;

                          @media (max-width: 500px){

                            font-size: 8px;
                          }
                        }
                      }
                    }
        
                    #project-actions{
                      height: fit-content;
                      position: relative;
                      /* margin: 20px 20px 20px 20px; */
                      top: 10px;
                      right: 10px;
                      z-index: 3;
                    
                      @media (max-width: 500px) {
                        top: 0px;
                        right: 5px;
                      }
                    }
                  }
                }
              }
        
        
              /* li{
                  background-color: ;
              } */
          }
    }
}
