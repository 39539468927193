#container-loading-modal-screen {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;
    align-items: center;
    justify-content: center;
}