#search-input {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(255, 255, 255);
    height: 25px;
    border-radius: 20px;
    background-color: rgb(136, 32, 32);
    border: none;
    outline: none;
    padding: 5px 40px; 
    background-image: url('../../assets/search-icon-white.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    width: fit-content;

  }
  
  #search-input:focus {
    outline: none;
  }
  
  #search-input::placeholder {
    color: white;
    opacity: 0.5;
  }