#container-form-general-config{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60%;
    max-width: 600px;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 20px;
    color: rgb(85, 85, 85);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    min-width: 200px;
    user-select: none;
}

#label-title{
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
    user-select: none;
    text-wrap: nowrap;

    @media(max-width: 600px){
        font-size: 14px;
    }
}

#container-button-submit-general-configurations{
    display: flex;
    justify-content: end;
    padding: 10px 0px 0px 0px;
}

#container-profile-photo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#container-photo{
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
}

#container-photo img{
    height: 80px;
    width: 80px;
    border-radius: 100%;
    object-fit: cover;

    @media (max-width: 600px){
        height: 60px;
        width: 60px;
    }
}

#button-upload-new-profile-photo{
    width: fit-content;
    margin-bottom: 20px;
    display: flex;
    width: fit-content;
    height: fit-content;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(70, 70, 70);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: none;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 10px;
    font-weight: bold;
}

#button-upload-new-profile-photo:hover {
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}

#container-inputs-form-general-config {
    flex-direction: column;
}

#container-label-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    user-select: none;
}

#input-form-general-config {
    margin-bottom: 10px;
    width: 100%;
    padding: 0px 5px 0px 5px;
    border: 1px solid rgb(70, 70, 70);
    height: 25px !important;
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12px;
    box-sizing: border-box;

    @media (min-width: 500px) {
        height: 20px;
    }

    @media (min-width: 600px) {
        height: 20px;
    }

    @media (min-width: 1000px) {
        height: 20px;
    }
}

#input-about-form-general-config{
    margin-bottom: 10px;
    border: 1px solid rgb(70, 70, 70);
    padding: 5px;
    height: 20vw;
    width: 100%;
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 2vw;
    box-sizing: border-box;
}

#container-inputs-form-general-config label{
    font-weight: bold;
    margin-bottom: 5px;
}

#button-submit-form-general-config{
    display: flex;
    width: fit-content;
    height: fit-content;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(70, 70, 70);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 18px;
    font-weight: bold;
    user-select: none;

    @media (min-width: 400px) {
        font-size: 18px;
        width: 80px;
    }

    @media (min-width: 1000px) {
        font-size: 18px;
        width: 100px;
    }
}

#button-submit-form-general-config:hover{
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}