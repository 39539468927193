#container-select-project-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    z-index: 1000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#container-h2-modal-project-selection-project-title{
    margin: 5px 0px 5px 0px;
}

#h2-modal-project-selection-project-title{
    margin: 0px;
}

#container-select-project {
    border-radius: 20px;
    position: fixed;
    max-width: 60vw;
    top: 10%;
    left: 0;
    right: 0;
    height: fit-content;
    margin: 0 auto;
    padding: 0.5em 1.5em 1.5em 1.5em;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);

    @media (min-width: 700px) {
        width: 100vw;
    }
}

#close-project-selection-modal {
    position: absolute;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 5px;
    top: -40px;
    right: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #ca2500;
    transition: background-color 0.5s ease
}

#close-project-selection-modal:hover {
    background-color: #a01d00;
    cursor: pointer;
}

#ul-list-projects-selection{
    list-style: none;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    margin: 0px;
    width: 100%;
    min-height: 20%;
    height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        transition: background-color 0.2s, height 0.2s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        height: 8px;
    }
}

#container-select-post{
    display: flex;
    align-items: center;
    height: 100%;
}

#container-li-projects-selection{
    background-color: #fafafa;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#container-project-modal-selection{
    width: 100%;
    height: fit-content;
}

#container-title-project-modal-select{
    width: 1px;
    z-index: 3;
}

#container-player-projects-track-title-modal-select-project {
    display: flex;
    height: fit-content;
    margin: 0;
    top: 25px;
    left: 10px;
    opacity: 0.6;
    margin-left: 5px;
    margin-top: 5px;
    width: fit-content;
    background-color: black;
    color: white;
    border-radius: 2px;
    padding: 2px 5px 2px 5px;
  }
  
  #container-player-projects-track-title-modal-select-project h5{
    display: flex;
    white-space: nowrap;
    margin: 0;
  }

#li-project-selection-modal-post{
    display: flex;
    flex-direction: row;
    height: 100%;
}

#container-project-player-projects-selection-post{
    display: flex;
}

#button-attach-project {
    margin-top: 10px;
    color: white;
    background-color: rgb(70, 70, 70);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#container-button-attach-project{
    display: flex;
    width: 100%;
    justify-content: end;
}

#button-attach-project:hover {
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}