#container-add-remove-user-instruments-background-modal {
    display: flex;
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 1000;
    height: 100%;
    width: 100%;

    #container-screen-transparent-add-remove-user-instruments-modal{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;

        #container-add-remove-user-instruments-modal {
            border-radius: 10px;
            width: 70%;
            height: fit-content;
            margin: 1em;
            padding: 1em;
            background-color: #FFF;
            box-shadow: 0 0 20px rgba(0,0,0, 0.8);

            @media (min-width: 700px){
                width: 50%;
            }

            #container-top-add-remove-user-instruments-modal {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h2{
                    margin: 0px;
                }

                button {
                    display: flex;
                    position: static;
                    height: 35px;
                    width: 35px;
                    border: 0;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    background-color: #ca2500;
                    transition: background-color 0.5s ease;
                    margin: 0px;
                    align-self: baseline;
                    cursor: pointer;
                }
            }
        
            #container-list-add-remove-user-instruments-modal {
                background-color: #fff;
                box-shadow: 0px 0px 4px rgba(0,0,0, 0.4);
                overflow-y: auto;
                position: relative;
                height: 300px;
                border-radius: 5px;
            
                scrollbar-width: auto;
                scrollbar-color: #888 transparent;
            
                &::-webkit-scrollbar {
                    width: 10px;
                }
            
                &::-webkit-scrollbar-thumb {
                    background-color: #888;
                    border-radius: 10px;
                    transition: background-color 0.2s, width 0.2s;
                }
            
                &::-webkit-scrollbar-thumb:hover {
                    background-color: #555;
                    width: 12px;
                }
            
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    height: fit-content;
                    flex-wrap: wrap;
                    justify-content: center;
            
                    #li-instruments-available {
                        padding: 5px 10px 5px 10px;
                        background-color: rgb(255, 255, 255);
                        border: 1px solid rgb(0, 190, 0);
                        color: rgb(0, 190, 0);
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                        width: fit-content;
                        height: fit-content;
                        cursor: pointer;
                        margin: 5px 0px 0px 2px;
                        &:hover {
                            border: 1px solid rgb(0, 190, 0);
                        }

                        h3{
                            margin: 0px;
                            width: fit-content;
                            white-space: nowrap;
                            user-select: none;
                        }
                    }

                    #li-user-instruments-already-selected {
                        padding: 5px 10px 5px 10px;
                        background-color: rgb(0, 190, 0);
                        border: 1px solid rgb(0, 190, 0);
                        color: rgb(255, 255, 255);
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                        width: fit-content;
                        height: fit-content;
                        cursor: pointer;
                        margin: 5px 0px 0px 2px;
                        &:hover {
                            border: 1px solid rgb(0, 190, 0);
                        }


                        h3{
                            margin: 0px;
                            width: fit-content;
                            white-space: nowrap;
                            user-select: none;
                        }
                    }
                }
            }
        }
    }
}