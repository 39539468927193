#container-modal-create-audio-recorded{
    display: flex;
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.6);
    align-items: center;
    z-index: 1000;
    height: 100%;
    width: 100%;

    #container-screen-transparent-audio-content-modal{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;

        #container-form-labels-create-content-recorded-modal{
            border-radius: 10px;
            width: 70%;
            height: fit-content;
            margin: 1em;
            padding: 1em 1em;
            background-color: #FFF;
            box-shadow: 0 0 20px rgba(0,0,0, 0.8);

            @media (min-width: 700px){
                width: 50%;
            }
            
            #container-top-form-labels-create-content-recorded-modal{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h1{
                    margin: 0px 0px 0px 5px;
                    font-size: 25px;
                }                
                
                button{
                    align-items: center;
                    background-color: #ca2500;
                    border: none;
                    border-radius: 5px;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    height: fit-content;
                    padding: 5px;
                    transition: background-color 0.5s ease;

                    &:hover {
                        background-color: #a01d00;
                        cursor: pointer;
                    }
                }
            }
        }

        #container-bottom-form-labels-create-content-recorded-modal{
            #submit-button-container{
                width: 100%;
                justify-content: end;
            }

            #container-form-add-content-recorded{

                margin: 10px 0px 0px 5px;

                div{
                    margin-top: 5px;

                    label{
                        margin-bottom: 5px;
                    }
                }

                #container-title{
                    width: 200px;
                }

                #container-description{
                    width: 100%;
                    box-sizing: border-box;

                    textarea{
                        width: 100%;
                        box-sizing: border-box;
                    }
                }

                #container-content-type{
                    width: 400px;
                }

                #container-instrument-selection{

                    select{
                        background-color: brown;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-weight: bold;
                        color: white;
                        border: none;
                        border-radius: 5px;
                        height: 30px;
                        width: 200px;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        text-align: center;
                      }
                }
            }
        }
    }
}