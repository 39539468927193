#container-form-access-config{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(70, 70, 70);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    align-items: center;
    padding: 20px;
    user-select: none;
}

#title-accessibility{
    font-weight: bold;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;

    @media (max-width: 500px) {
        font-size: 14px;
    }
}

#container-bottom-access-config{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    justify-content: left;
}

#container-change-password-link{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    align-items:end;
    justify-content: left;
}

#label-password-change {
    color: blue;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    font-size: 4vw;
    text-align: right;

    @media (min-width: 300px) {
        font-size: 10px;
        width: 100px;
    }
}

#label-password-change:hover {
    color: darkblue;
}

.container-input-form-access-config{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container-input-form-access-config label{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.container-inputs-form-access-config input{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: 25px;
    width: 100%;
    border: 1px solid rgb(70, 70, 70);
}

.container-access-idiom-change {
    display: flex;
    flex-direction: row;
    width: 500px;
    align-items: center;
    height: fit-content;

    @media(max-width: 800px){
        width: 400px;
    }

    @media(max-width: 600px){
        width: 300px;
    }

    @media(max-width: 500px){
        width: 200px;
    }
}

#change-idiom-input {
    display: flex;
    height: 5vw;
    width: 20vw;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid rgb(172, 172, 172);
    background-color: rgb(233, 233, 233);

    @media (min-width: 200px) {
        height: 15px;;
        width: 30vw;
    }

    @media (min-width: 500px) {
        height: 20px;;
        width: 200px;
    }
}

.change-idiom-input:disabled {
    background-color: #f0f0f0; /* Cor de fundo para indicar desativado */
    border: 1px solid #d9d9d9; /* Cor da borda para indicar desativado */
    color: #999; /* Cor do texto para indicar desativado */
    cursor: not-allowed; /* Altera o cursor para 'não permitido' para indicar desativado */
}

#container-button-confirm-access-configurations {
    display: flex;
    justify-content: end;
    width: 100%;
    /* padding-top: 20px; */
    /* padding-right: 300px; */
}

#container-button-confirm-access-configurations button{
    display: flex;
    width: fit-content;
    height: fit-content;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(70, 70, 70);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s;

    /* @media (max-width: 400px) {
        height: 40px;
    }

    @media (max-width: 500px) {
        font-size: 20px;
        height: 40px;
        width: 100px;
    } */
}

.container-button-confirm-access-configurations button:hover{
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}