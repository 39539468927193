#container-form-labels-create-post{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 10px;
    border-radius: 8px;
    height: fit-content;
    width: 100%;
    background-color: rgb(245, 245, 245);
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

#container-top{
    margin: 10px 10px 10px 10px;
    
}

#h4-post-owner{
    margin: 0px;
}

#container-profile-pic-text-area{
    display: flex;
    flex-direction: row;
}

#img-profile-image{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 5px;
}

#text-area-form-post-create {
    border: 1px solid #ccc; /* Adiciona uma borda sólida */
    border-radius: 5px; /* Adiciona bordas arredondadas */
    padding: 10px; /* Adiciona um espaço interno */
    font-size: 16px; /* Define o tamanho da fonte */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra suave */
    background-color: #f8f8f8; /* Define uma cor de fundo */
    resize: vertical; /* Permite redimensionamento vertical */
    width: 100%; /* Define uma largura de 100% */
    outline: none; /* Remove o contorno ao focar */
    margin-right: 10px;
}

#text-area-form-post-create:focus {
    border-color: #007bff; /* Altera a cor da borda quando focado */
}

#container-middle{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: right;
}

#container-project-selected-button-selection{
    margin: 10px 10px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 5px 10px 5px;
}

#container-no-project-selected{
    height: 100%;
}

#container-no-project-selected h2{
    width: 100%;
    text-align: center;
    color: rgb(150, 150, 150);
}

#container-project-selected-post{
    margin-right: 10px;
    min-height: 100px;
    border-radius: 10px;
    border: 1px solid rgb(189, 189, 189);
}

#container-button-select-project{
    display: flex;
    justify-content: right;
}

#button-select-project {
    margin: 10px 10px 0px 0px;
    color: white;
    background-color: rgb(70, 70, 70);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#button-select-project:hover{
  background-color: rgb(0, 0, 0);
  box-shadow: none;
}

#container-bottom {
    display: flex;
    width: 100%;
    justify-content: right;
    margin-bottom: 10px;
}

#button-post {
    margin: 10px 10px 0px 0px;
    color: white;
    background-color: rgb(70, 70, 70);
    border-radius: 5px;
    border: none;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px 30px 10px 30px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

#button-post:hover {
    background-color: rgb(0, 0, 0);
    box-shadow: none;
}